import { HtmlHTMLAttributes } from 'react';

import cx from 'classnames';

import { useSelector } from 'reduxStore/hooks';

import style from './userInfo.module.scss';
import { getCurrentOrganization } from "../../reduxStore/organization/organization.slice";

type UserInfoProps = HtmlHTMLAttributes<HTMLDivElement> & {
  className?: string;
};

export default function UserInfo({ className, ...htmlProps }: UserInfoProps) {
  const { user } = useSelector(state => state.auth);
  const organization = useSelector(getCurrentOrganization);
  const organizationId = organization.pk.split('#').pop();
  const username = user?.name || user?.username;
  const avatar = user?.avatar || user?.iconURL;
  const initials = username
    ?.split(' ')
    .slice(0, 2)
    .map(str => str.charAt(0));

  return (
    <div className={cx(style.userInfo, className)} {...htmlProps}>
      <div className={style.thumbnail}>
        {avatar ? (
          <img
            src={`https://cdn.discordapp.com/avatars/${user.id}/${avatar}.png?size=240`}
            alt="user thumbnail"
          />
        ) : (
          <>{initials}</>
        )}
      </div>
      <div className={style.detail}>
        <div className={style.username}>{username}</div>
        <div>
          Logged in with{' '}
          <span className={style.platform}>{user?.platform}</span>
        </div>
        <div className={style.platform}>Organization: {organizationId}</div>
      </div>
    </div>
  );
}
