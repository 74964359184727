import * as React from 'react';

import {
  Viewport as RToastViewport,
  Provider as RToastProvider,
} from '@radix-ui/react-toast';

import { useDispatch, useSelector } from 'reduxStore/hooks';
import { toastActions } from 'reduxStore/toast/toast.slice';

import Toast from './toast';

import style from './toast.module.scss';

export default function ToastProvider() {
  const { toasts } = useSelector(state => state.toast);
  const dispatch = useDispatch();

  return (
    <RToastProvider swipeDirection="right">
      {toasts.map(item => (
        <Toast
          id={item.id}
          key={item.id}
          title={item.title}
          status={item.status}
          description={item.description}
          onClose={id => dispatch(toastActions.removeToast(id))}
        />
      ))}
      <RToastViewport className={style.viewport} />
    </RToastProvider>
  );
}
