import { Toast } from 'types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const TOAST_FEATURE_KEY = 'toast';

export interface ToastState {
  toasts: Toast[];
}

const initialToastState: ToastState = {
  toasts: [],
};

export const toastSlice = createSlice({
  name: TOAST_FEATURE_KEY,
  initialState: initialToastState,
  reducers: {
    addToast(state, action: PayloadAction<Omit<Toast, 'id'>>) {
      state.toasts.push({
        id: new Date().valueOf(),
        ...action.payload,
      });
    },
    removeToast(state, action: PayloadAction<number>) {
      const id = action.payload;
      const index = state.toasts.findIndex(n => n.id === id);
      state.toasts.splice(index, 1);
    },
  },
});

export const toastActions = toastSlice.actions;
export const toastReducer = toastSlice.reducer;
