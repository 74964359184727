import { PlanDetail } from 'types';

import { createSlice } from '@reduxjs/toolkit';

import * as service from 'services/plan.service';

import { createAsyncThunk } from '../reduxUtils';
import { RootState } from '../store';

export const PLAN_FEATURE_KEY = 'plan';

export interface PlanState {
  plans: PlanDetail[];
}

const initialPlanState: PlanState = {
  plans: [],
};

export const preloadPlans = createAsyncThunk('plan/preloadPlans', async () => {
  return service.fetchPlans();
});

const planSlice = createSlice({
  name: PLAN_FEATURE_KEY,
  initialState: initialPlanState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(preloadPlans.fulfilled, (state, { payload }) => {
      state.plans = payload;
    });
  },
});

export const planActions = planSlice.actions;
export const planReducer = planSlice.reducer;

export const getPlanState = (rootState: RootState): PlanState => {
  return rootState[PLAN_FEATURE_KEY];
};
