import { useState, useRef, KeyboardEvent } from 'react';

import { CheckIcon, CloseIcon, EditIcon } from 'icons/icons';

import style from './editableText.module.scss';

type EditableTextProps = {
  text: string;
  onSave: (value: string) => void;
};

export default function EditableText({ text, onSave }: EditableTextProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(text);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSave = async () => {
    await onSave(value);

    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setValue(text);
  };

  const handleOnKeyUp = async (e: KeyboardEvent<HTMLInputElement>) => {
    const isEscKeyPressed = e.key === 'Escape';
    const isEnterKeyPressed = e.key === 'Enter';

    if (isEscKeyPressed) {
      return handleCancel();
    }

    if (isEnterKeyPressed) {
      return handleSave();
    }
  };

  const focusInput = () => setTimeout(() => inputRef.current?.focus(), 10);

  if (isEditing) {
    return (
      <div className={style.editableText}>
        <div className={style.inner}>
          <input
            ref={inputRef}
            className={style.input}
            readOnly={!isEditing}
            value={value}
            onKeyUp={handleOnKeyUp}
            onChange={e => setValue(e.target.value)}
          />
          <div className={style.actions}>
            <button onClick={handleSave} aria-label="save">
              <CheckIcon />
            </button>
            <button onClick={handleCancel} aria-label="cancel">
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={style.valueText}>{value}</div>
      <button
        aria-label="edit"
        className={style.editButton}
        onClick={() => {
          setIsEditing(true);
          focusInput();
        }}
      >
        <EditIcon />
      </button>
    </>
  );
}
