import cx from 'classnames';

import Binance from 'images/chainsRight/binance.png';
import Gnosis from 'images/chainsRight/gnosis.png';
import Near from 'images/chainsRight/near.png';
import Optimism from 'images/chainsRight/optimism.png';
import Palm from 'images/chainsRight/palm.png';
import Polkadot from 'images/chainsRight/polkadot.png';
import Polygon from 'images/chainsRight/polygon.png';
import Solana from 'images/chainsRight/solana.png';
import Tezos from 'images/chainsRight/tezos.png';
import XRP from 'images/chainsRight/xrp.png';

import s from './chainCarousel.module.scss';

const ChainCarouselRight = () => {
  return (
    <div id="infinite" className={s.highwaySlider}>
      <div className={cx(s.container, s.highwayBarrier)}>
        <ul className={s.highwayLane}>
          <li className={s.highwayCarRight}>
            <img src={Gnosis} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Binance} height="45px" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Near} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Optimism} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Palm} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Polkadot} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Polygon} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Solana} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Tezos} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={XRP} height="auto" width="auto" alt="" />
          </li>

          <li className={s.highwayCarRight}>
            <img src={Gnosis} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Binance} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Near} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Optimism} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Palm} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Polkadot} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Polygon} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Solana} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={Tezos} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCarRight}>
            <img src={XRP} height="auto" width="auto" alt="" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ChainCarouselRight;
