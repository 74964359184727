import { PropsWithChildren } from 'react';
import { Control, useController } from 'react-hook-form';

import InputBase, { InputBaseProps } from './inputBase';

import style from './inputBase.module.scss';

type RadioProps = InputBaseProps &
  PropsWithChildren & {
    options: { key: string; value: string; disabled?: boolean }[];
    defaultOption?: string;
    control: Control<any>;
    name: string;
  };

export default function Radio({
  label,
  name,
  options,
  control,
  defaultOption,
  children,
  ...props
}: RadioProps) {
  const {
    field,
  } = useController({
    name,
    control,
    rules: { required: true },
  });
  return (
    <InputBase {...props} label={label}>
      <div className={style.input} style={{ display: 'flex', gap: '20px' }}>
        {options.map(option => (
          <div key={option.key} style={{ display: 'flex' }}>
            <input
              style={{ margin: '0px 8px 2px 5px' }}
              type="radio"
              value={option.key}
              disabled={option.disabled}
              checked={field.value === option.key}
              onChange={field.onChange} // send value to hook form
              onBlur={field.onBlur} // notify when input is touched/blur
              name={field.name} // send down the input name
              ref={field.ref}
            />
            <label style={{ alignSelf: 'end' }} htmlFor={option.key}>
              {option.value}
            </label>
          </div>
        ))}
      </div>
    </InputBase>
  );
}
