import { Wallet } from 'types';

import { createSlice } from '@reduxjs/toolkit';

import * as service from 'services/wallet.service';

import { createAsyncThunk } from '../reduxUtils';
import { toastActions } from '../toast/toast.slice';

export const WALLET_FEATURE_KEY = 'wallet';

export interface WalletState {
  isWalletsBeingFetched: boolean;
  isWalletBeingCreated: boolean;
  wallet: Wallet | null;
}

export const getWallet = createAsyncThunk(
  'wallet/getWallet',
  async (organizationId: string, { dispatch }) => {
    const wallet = await service.fetchWallet(organizationId);

    if (wallet.status === 'pending') {
      dispatch(getWalletUntilBecomeActive(organizationId));
    }

    return wallet;
  },
);

export const addWallet = createAsyncThunk(
  'wallet/addWallet',
  async (organizationId: string, { dispatch }) => {
    try {
      const wallet = await service.addWallet(organizationId);
      dispatch(
        toastActions.addToast({
          status: 'success',
          title: 'Cloud wallet is being created',
        }),
      );

      if (wallet.status === 'pending') {
        dispatch(getWalletUntilBecomeActive(organizationId));
      }

      return wallet;
    } catch (err) {
      dispatch(
        toastActions.addToast({
          status: 'error',
          title: 'Cloud wallet not created',
        }),
      );
      throw err;
    }
  },
);

const getWalletUntilBecomeActive = createAsyncThunk(
  'wallet/getWalletUntilBecomeActive',
  async (organizationId: string, { dispatch }) => {
    const waitFor = async (ms: number) =>
      new Promise(resolve => setTimeout(resolve, ms));

    const wallet = await service.fetchWallet(organizationId);

    if (wallet.status === 'pending') {
      const fifteenSecs = 15000;

      await waitFor(fifteenSecs);

      dispatch(getWalletUntilBecomeActive(organizationId));
    }

    if (wallet.status === 'active') {
      dispatch(
        toastActions.addToast({
          status: 'success',
          title: 'Cloud wallet is now active',
        }),
      );
    }

    return wallet;
  },
);

export const initialWalletState: WalletState = {
  isWalletsBeingFetched: false,
  isWalletBeingCreated: false,
  wallet: null,
};

const walletSlice = createSlice({
  name: WALLET_FEATURE_KEY,
  initialState: initialWalletState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getWallet.pending, state => {
      state.isWalletsBeingFetched = true;
    });
    builder.addCase(getWallet.fulfilled, (state, { payload }) => {
      state.isWalletsBeingFetched = false;
      state.isWalletBeingCreated = payload.status === 'pending';
      if (payload.status === 'active') {
        state.wallet = payload;
      }
    });
    builder.addCase(getWallet.rejected, state => {
      state.isWalletsBeingFetched = false;
    });
    builder.addCase(
      getWalletUntilBecomeActive.fulfilled,
      (state, { payload }) => {
        state.isWalletBeingCreated = payload.status === 'pending';
        if (payload.status === 'active') {
          state.wallet = payload;
        }
      },
    );
    builder.addCase(addWallet.pending, state => {
      state.isWalletBeingCreated = true;
    });
    builder.addCase(addWallet.fulfilled, (state, { payload }) => {
      state.isWalletBeingCreated = payload.status === 'pending';
      if (payload.status === 'active') {
        state.wallet = payload;
      }
    });
    builder.addCase(addWallet.rejected, state => {
      state.isWalletBeingCreated = false;
    });
  },
});

export const walletReducer = walletSlice.reducer;
export const walletActions = walletSlice.actions;
