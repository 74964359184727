import type { Dispatch } from 'redux';

import { createAsyncThunk as originalCreateAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkPayloadCreator, AsyncThunk } from '@reduxjs/toolkit';

type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

const fullSerializeError = (err: any) => {
  return {
    name: err.name,
    message: err.message,
    stack: err.stack,
    statusCode: err.statusCode,
  };
};

export const createAsyncThunk = <
  Returned,
  ThunkArg = void,
  ThunkApiConfig extends AsyncThunkConfig = {},
>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
): AsyncThunk<Returned, ThunkArg, AsyncThunkConfig> => {
  return originalCreateAsyncThunk(typePrefix, payloadCreator, {
    serializeError: fullSerializeError,
  });
};
