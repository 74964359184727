import React, { useEffect, useState, useRef, PropsWithChildren } from 'react';

import cx from 'classnames';

import style from './mask.module.scss';

export type MaskProps = PropsWithChildren &
  React.HTMLAttributes<HTMLDivElement> & {
    show: boolean;
  };

export default function Mask({
  show,
  onClick,
  children,
  ...htmlProps
}: MaskProps) {
  const [isVisible, setIsVisible] = useState(show);
  const timeoutRef = useRef<number | null>(null);
  const classNames = cx(style.backdrop, {
    [style.fadeIn]: show,
    [style.fadeOut]: !show,
  });

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      return;
    }

    const hideWithDelay = (delay: number) => {
      timeoutRef.current = window.setTimeout(() => {
        setIsVisible(false);
        timeoutRef.current = null;
      }, delay);
    };

    hideWithDelay(200);

    return () => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, [show]);

  if (!isVisible) {
    return null;
  }
  return (
    <div
      className={classNames}
      onClick={onClick}
      data-testid="mask-backdrop"
      {...htmlProps}
    >
      {children}
    </div>
  );
}
