import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ConsumerClientAppClaim, ConsumerClientAppInput } from 'types';

import {
  addConsumerClientApp,
  updateClientApp,
} from 'reduxStore/consumer/consumer.slice';
import { useDispatch, useSelector } from 'reduxStore/hooks';
import { getCurrentOrganization } from 'reduxStore/organization/organization.slice';

import useClipboard from 'hooks/useClipboard';

import Button from 'components/button/button';
import CopyApiKey from 'components/consumer/copyApiKey';
import Spinner from 'components/spinner/spinner';

import { dashboardUrl } from '../../constants';
import ClientAppSubmissionForm from './clientAppSubmissionForm';

import style from './clientAppApplication.module.scss';

export default function ClientAppApplication() {
  const { claimRequests, isConsumersBeingFetched } = useSelector(
    state => state.consumer,
  );
  const navigate = useNavigate();
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganization).pk;
  if (claimRequests.length === 0 && clientId) {
    navigate('/consumer/client-app/add');
  }

  const { copy } = useClipboard({ name: 'Secret key' });
  const [clientApp, setClientApp] = useState<null | ConsumerClientAppClaim>(
    null,
  );
  let editClientApp: ConsumerClientAppClaim | undefined;
  if (clientId && !isConsumersBeingFetched) {
    editClientApp = claimRequests.find(
      claimRequest => claimRequest.id === clientId,
    );
  }
  const consumerState = useSelector(state => state.consumer);

  async function submitForm(data: ConsumerClientAppInput) {
    if (editClientApp) {
      await dispatch(updateClientApp({ sk: editClientApp.sk, data })).unwrap();

      navigate(dashboardUrl);
      return;
    }
    const payload = await dispatch(
      addConsumerClientApp({ organizationId, data }),
    ).unwrap();

    setClientApp(payload);
  }

  const onCancel = () => navigate(dashboardUrl);

  return (
    <div className={style.clientAppApplication}>
      {clientApp ? (
        <>
          <CopyApiKey
            clientApp={clientApp}
            isNew={true}
            onCopy={data => copy(data)}
          />
          <div className={style.goBackButtonWrapper}>
            <Button outline onClick={() => navigate(dashboardUrl)}>
              Return to Dashboard
            </Button>
          </div>
        </>
      ) : (
        <>
          <h2 className={style.title}>
            {editClientApp ? 'Update Client app' : 'Request API Access'}
          </h2>
          <ClientAppSubmissionForm
            onSubmit={submitForm}
            onCancel={onCancel}
            mode={editClientApp ? 'edit' : 'add'}
            clientApp={editClientApp}
          />
        </>
      )}
      <Spinner
        fullPage
        show={consumerState.isConsumerBeingAdded}
        data-testid="load-spinner"
      />
    </div>
  );
}
