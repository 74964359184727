import {
  ArrayPath,
  Control,
  FieldErrorsImpl,
  FieldValues,
  Path,
  useFieldArray,
  UseFormRegister,
} from 'react-hook-form';

import { CloseIcon, PlusIcon } from 'icons/icons';

import Button from 'components/button/button';
import { TextInput } from 'components/form';
import InputBase from 'components/form/inputBase';

import style from './multiLineInput.module.scss';

type MultiLineInputProps<T extends FieldValues> = {
  name: string;
  label?: string;
  keyName: string;
  required?: boolean;
  placeholder: string;
  register: UseFormRegister<T>;
  control: Control<T, string>;
  errors?: FieldErrorsImpl<{ [k: string]: string }>[];
};
export default function MultiLineInput<T extends FieldValues>({
  name,
  label,
  keyName,
  control,
  required,
  register,
  placeholder,
  errors,
}: MultiLineInputProps<T>) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: name as ArrayPath<T>,
  });
  const inputs = fields.map((field, index) => (
    <div key={field.id} className={style.row}>
      <TextInput
        placeholder={placeholder}
        register={{ ...register(`${name}.${index}.${keyName}` as Path<T>) }}
        error={errors?.[index]?.[keyName]}
      />
      {index !== 0 && (
        <Button
          outline
          size="small"
          className={style.button}
          type="button"
          data-testid="remove-button"
          onClick={() => {
            remove(index);
          }}
        >
          <CloseIcon />
        </Button>
      )}
    </div>
  ));

  return (
    <InputBase
      label={label ?? name}
      required={required}
      data-testid="multi-line-input"
    >
      {/*<div data-testid="multi-line-input">*/}
      <div className={style.fields}>{inputs}</div>
      <Button
        outline
        size="small"
        type="button"
        className={style.addRowButton}
        onClick={() => {
          // @ts-ignore
          append({
            [keyName]: '',
          });
        }}
      >
        <PlusIcon className={style.plusIcon} /> Add Row
      </Button>
      {/*</div>*/}
    </InputBase>
  );
}
