import { Middleware } from 'redux';

import { signOut } from './auth/auth.slice';

const unauthorized = 401;

export const errorHandleMiddleware: Middleware = () => next => action => {
  const result = next(action);

  if (result.error?.statusCode === unauthorized) {
    next(signOut({ isTokenExpired: true }));
  }

  return result;
};
