import { useDispatch } from 'reduxStore/hooks';
import { toastActions } from 'reduxStore/toast/toast.slice';

type Props = {
  name: string;
};

export default function useClipboard({ name }: Props) {
  const dispatch = useDispatch();

  const copy = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      dispatch(
        toastActions.addToast({
          status: 'success',
          title: `${name} copied`,
        }),
      );
    } catch (err) {
      dispatch(
        toastActions.addToast({
          status: 'error',
          title: `${name} could not be copied`,
        }),
      );
    }
  };

  return { copy };
}
