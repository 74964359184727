import {
  useDispatch as useDispatchOriginal,
  useSelector as useSelectorOriginal,
} from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import type { AppDispatch, RootState } from './store';

export const useDispatch: () => AppDispatch = useDispatchOriginal;
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorOriginal;
