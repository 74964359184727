import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Mixpanel } from 'mixpanel';
import { ConsumerClientAppClaim, ConsumerClientAppInput } from 'types';
import * as yup from 'yup';
import { AnySchema } from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/button/button';
import { TextInput } from 'components/form';
import Message from 'components/message/message';

import MultiLineInput from '../../components/Multiline/multiLineInput';
import Radio from '../../components/form/radio';
import * as service from '../../services/organization.service';

import style from './clientAppSubmissionForm.module.scss';

const schema = yup
  .object<Record<keyof ConsumerClientAppInput, AnySchema>>({
    name: yup.string().required('Name is required'),
    email: yup
      .string()
      .required('Email is required')
      .email('Wrong Email address.'),
    icon: yup.string().optional().url('Invalid Icon Url.'),
    url: yup.string().optional(),
    feedback: yup.string().optional(),
    redirectUris: yup
      .array()
      .when('clientType', {
        is: 'public',
        then: yup
          .array()
          .min(1, 'At least one Redirect URI is required')
          .of(
            yup.object({
              url: yup.string().required(),
            }),
          ),
      })
      .when('clientType', {
        is: 'confidential',
        then: yup
          .array()
          .min(1, 'At least one Redirect URI is required')
          .of(
            yup.object({
              url: yup.string().required(),
            }),
          ),
      })
      .optional(),
    clientType: yup.string().required(),
    allowedOrigins: yup.string().optional(),
    allowedReferrers: yup.string().optional(),
    allowedIpAddresses: yup.string().optional(),
    allowedGrantTypes: yup.string().optional(),
    // requestedScopes: yup
    //   .array()
    //   .of(yup.string())
    //   .min(1)
    //   .required('Scope is required'),
  })
  .required();

export default function ClientAppSubmissionForm({
  onCancel,
  onSubmit,
  mode = 'add',
  clientApp,
}: {
  onCancel: () => void;
  onSubmit: SubmitHandler<ConsumerClientAppInput>;
  mode?: 'edit' | 'add';
  clientApp?: ConsumerClientAppClaim;
}) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<ConsumerClientAppInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      clientType: 'public',
      redirectUris: [{ url: '' }],
    },
    values: clientApp
      ? service.transformClientAppToFormData(clientApp)
      : ({
          clientType: 'public',
          redirectUris: [{ url: '' }],
        } as ConsumerClientAppInput),
  });
  const errorMessage = useMemo(() => {
    if (!Object.keys(errors).length) {
      return null;
    }
    return Object.values(errors)?.[0]?.message || 'Some fields are missing';
  }, [errors]);
  return (
    <form
      key={clientApp?.id ?? 0}
      onSubmit={handleSubmit(onSubmit)}
      data-testid="submission-form"
    >
      {errorMessage ? <Message>{errorMessage}</Message> : null}
      <TextInput
        showClear={Boolean(watch('name'))}
        label="Name"
        required={true}
        register={register('name')}
        error={errors.name}
        placeholder="Application's name"
        onClear={() => setValue('name', '')}
      />
      <TextInput
        showClear={Boolean(watch('icon'))}
        label="Icon URL"
        register={register('icon')}
        error={errors.icon}
        placeholder="URL"
        onClear={() => setValue('icon', '')}
      />
      <Radio
        label="Client Type"
        options={[
          { key: 'public', value: 'Public' },
          { key: 'confidential', value: 'Confidential' },
          { key: 'apiKey', value: 'API Key' },
        ]}
        required={true}
        control={control}
        name={'clientType'}
        // onClear={() => setValue('clientType', 'public')}
        error={errors.clientType}
      ></Radio>
      <TextInput
        showClear={Boolean(watch('email'))}
        label="Email"
        register={register('email')}
        required={true}
        error={errors.email}
        placeholder="Application's email"
        onClear={() => setValue('email', '')}
      />
      {/*
      <TextArea
        showClear={Boolean(watch('allowedGrantTypes'))}
        label="Grant types"
        register={register('allowedGrantTypes')}
        error={errors.allowedGrantTypes}
        placeholder="Grant types"
        onClear={() => setValue('allowedGrantTypes', '')}
      /> */}
      <TextInput
        showClear={Boolean(watch('url'))}
        label="Project URL"
        register={register('url')}
        error={errors.url}
        placeholder="URL"
        onClear={() => setValue('url', '')}
      />
      <MultiLineInput
        register={register}
        required={['confidential', 'public'].includes(
          getValues('clientType') ?? '',
        )}
        name={'redirectUris'}
        label={'Redirect URI'}
        keyName={'url'}
        placeholder={'Redirect URI'}
        control={control}
        errors={
          Array.isArray(errors.redirectUris)
            ? errors.redirectUris.map(err => ({
                url: err?.url,
              }))
            : undefined
        }
      />
      <TextInput
        showClear={Boolean(watch('feedback'))}
        label="How did you hear about us"
        register={register('feedback')}
        error={errors.feedback}
        placeholder="How did you hear about us"
        onClear={() => setValue('feedback', '')}
      />
      {/*<Controller
        control={control}
        render={({ field: { onChange } }) => (
          <ScopesInput error={errors.requestedScopes} onChange={onChange} />
        )}
        name="requestedScopes"
      /> */}
      {/*<TextArea*/}
      {/*  showClear={Boolean(watch('allowedOrigins'))}*/}
      {/*  label="Origin URLs"*/}
      {/*  register={register('allowedOrigins')}*/}
      {/*  error={errors.allowedOrigins}*/}
      {/*  placeholder="Origins"*/}
      {/*  onClear={() => setValue('allowedOrigins', '')}*/}
      {/*/>*/}
      {/* <TextArea
        showClear={Boolean(watch('allowedReferrers'))}
        label="Referrers"
        register={register('allowedReferrers')}
        error={errors.allowedReferrers}
        placeholder="Referrers"
        onClear={() => setValue('allowedReferrers', '')}
      />
      <TextArea
        showClear={Boolean(watch('allowedIpAddresses'))}
        label="IP Addresses"
        register={register('allowedIpAddresses')}
        error={errors.allowedIpAddresses}
        placeholder="IP Addresses"
        onClear={() => setValue('allowedIpAddresses', '')}
      /> */}
      <div className={style.actions}>
        <Button
          outline
          type="button"
          onClick={() => {
            Mixpanel.track('dev-portal client-app-cancel-btn', {
              button: 'Cancel',
            });
            onCancel();
          }}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          data-testid="submit-button"
          onClick={() => {
            Mixpanel.track('dev-portal client-app-submit-btn', {
              button: 'Submit',
            });
          }}
        >
          {mode === 'add' ? 'Submit' : 'Update'}
        </Button>
      </div>
    </form>
  );
}
