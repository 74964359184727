import { useSelector } from 'reduxStore/hooks';

import AddOrganization from './addOrganization';
import Consumer from './sections/consumer/consumer';
import Contributor from './sections/contributor/contributor';
import PlanInfo from './sections/planInfo/planInfo';
import UserInfo from './userInfo';
import VerifyOrganization from './verifyOrganization';

import style from './dashboard.module.scss';

export default function Dashboard() {
  const {isVerificationOtpSend, organization,} = useSelector(state => state.organization);
  const showAddOrganization = (!organization) || !(organization.email && organization.name);
  if (showAddOrganization && isVerificationOtpSend) {
    return <VerifyOrganization />;
  }
  if (showAddOrganization) {
    return <AddOrganization data-testid="add-organization" />;
  }

  return (
    <div className={style.dashboard} data-testid="dashboard">
      <UserInfo className={style.userInfo} data-testid="user-info" />
      <PlanInfo />
      <Consumer className={style.section} data-testid="api-submissions" />
      <Contributor className={style.section} data-testid="marketplace" />
    </div>
  );
}
