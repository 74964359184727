import { UseFormRegisterReturn } from 'react-hook-form';

import InputBase, { InputBaseProps } from './inputBase';

import style from './inputBase.module.scss';

type TextAreaProps = InputBaseProps & {
  placeholder: string;
  register: UseFormRegisterReturn;
};

export default function TextArea({
  placeholder,
  register,
  ...props
}: TextAreaProps) {
  return (
    <InputBase {...props}>
      <textarea
        placeholder={placeholder}
        className={style.textarea}
        {...register}
      />
    </InputBase>
  );
}
