import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useSignIn from 'hooks/useSignIn';

import Spinner from 'components/spinner/spinner';

export function Protected() {
  const location = useLocation();
  const { isSignedIn, isLoading } = useSignIn();

  if (isLoading) {
    return <Spinner show={isLoading} fullPage data-testid="loading" />;
  }

  if (!isSignedIn) {
    return <Navigate replace to="/signin" state={{ from: location }} />;
  }

  return <Outlet />;
}

export default Protected;
