import { CollabLandUser } from 'types';

import { createAction, createSlice } from '@reduxjs/toolkit';

import {
  getUser,
  initialize,
  signOut as signOutUser,
} from 'services/auth.service';

import { getOrganizations } from '../organization/organization.slice';
import { createAsyncThunk } from '../reduxUtils';
import { RootState } from '../store';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  isLoading: boolean;
  isTokenExpired: boolean;
  user: CollabLandUser | null;
}

export const authenticateUser = createAsyncThunk(
  'auth/authenticate',
  async ({ token }: { token: string | null }, { dispatch }) => {
    initialize(token);
    try {
      const user = await getUser();
      dispatch(getOrganizations());
      return user;
    } catch (err) {
      dispatch(signOut());
      throw err;
    }
  },
);

export const SIGNOUT_ACTION = 'auth/signOut';

export const signOut = createAction(
  SIGNOUT_ACTION,
  (payload: { isTokenExpired?: boolean } = {}) => {
    signOutUser();
    return {
      payload,
    };
  },
);

export const initialAuthState: AuthState = {
  isLoading: true,
  isTokenExpired: false,
  user: null,
};

export const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState: initialAuthState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(signOut, (state: AuthState, { payload }) => {
        state.isLoading = false;
        state.user = null;
        if (payload.isTokenExpired !== undefined) {
          state.isTokenExpired = payload.isTokenExpired;
        }
      })
      .addCase(authenticateUser.pending, (state: AuthState) => {
        state.isLoading = true;
        state.isTokenExpired = false;
      })
      .addCase(authenticateUser.fulfilled, (state: AuthState, { payload }) => {
        state.isLoading = false;
        state.user = payload;
      })
      .addCase(authenticateUser.rejected, (state: AuthState) => {
        state.isLoading = false;
        state.user = null;
      });
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;

export const getAuthState = (rootState: RootState): AuthState =>
  rootState[AUTH_FEATURE_KEY];
