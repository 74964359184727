import { useState } from 'react';

import { CopyIcon, VisibilityIcon, VisibilityOffIcon } from 'icons/icons';

import useClipboard from 'hooks/useClipboard';

import style from './secretKey.module.scss';

type SecretKeyProps = {
  value: string;
};

export default function SecretKey({ value }: SecretKeyProps) {
  const [isKeyVisible, setIsKeyVisible] = useState(false);
  const { copy } = useClipboard({ name: 'Secret key' });

  return (
    <div className={style.container}>
      <input
        readOnly
        type={isKeyVisible ? 'text' : 'password'}
        value={isKeyVisible ? value : 'placeholder-hidden-key'}
        className={style.input}
      />
      <button
        data-testid="copy-button"
        className={style.iconButton}
        onClick={() => copy(value)}
      >
        <CopyIcon />
      </button>
      <button
        data-testid="eye-button"
        className={style.iconButton}
        onClick={() => setIsKeyVisible(!isKeyVisible)}
      >
        {isKeyVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </button>
    </div>
  );
}
