import { Location, useLocation } from 'react-router-dom';

import config from 'config';

interface LocationWithState extends Location {
  state: {
    from?: {
      pathname?: string;
    };
  };
}

export default function useSignInUrl() {
  const location = useLocation() as LocationWithState;
  let redirectURI =
    window.location.origin + (location.state?.from?.pathname ?? '');

  redirectURI += redirectURI.endsWith('/') ? '' : '/';
  const queryParams = new URLSearchParams({
    client_id: config.clientId,
    redirect_uri: redirectURI,
    response_type: 'token',
    state: 'devportal',
  }).toString();
  return (
    `${config.apiUrl}/oauth2/authorize?${queryParams}`
  );
}