import { useEffect, useState } from 'react';

import style from './image.module.scss';

const isURLValidImage = async (url: string) => {
  try {
    const response = await fetch(url);
    if (response.ok) {
      const contentType = response.headers.get('Content-Type');
      return contentType?.startsWith('image/');
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const Avatar = ({
  imageUrl,
  name,
}: {
  imageUrl?: string;
  name: string;
}) => {
  const [image, setImage] = useState<string>();
  const initials = name
    ?.split(' ')
    .slice(0, 2)
    .map(str => str.charAt(0));
  useEffect(() => {
    if (imageUrl) {
      isURLValidImage(imageUrl)
        .then(isValid => {
          if (isValid) {
            setImage(imageUrl);
          }
        })
        .catch(e => {});
    }
  }, [imageUrl]);
  return (
    <div className={style.thumbnail}>
      {image ? <img src={image} alt={name} /> : initials}
    </div>
  );
};
