import { OrganisationInput, Organization, OtpVerificationInput } from 'types';

import { createSlice } from '@reduxjs/toolkit';

import * as service from 'services/organization.service';

import { signOut } from '../auth/auth.slice';
import { createAsyncThunk } from '../reduxUtils';
import { RootState } from '../store';
import { toastActions } from '../toast/toast.slice';

export const ORGANIZATION_FEATURE_KEY = 'organization';

export interface OrganizationState {
  isOrganizationsBeingFetched: boolean;
  isOrganizationBeingAdded: boolean;
  isVerificationOtpSend?: boolean;
  name?: string;
  email?: string;
  organization?: Organization;
}

export const getOrganizations = createAsyncThunk(
  'organization/getOrganizations',
  async () => {
    return service.fetchOrganization();
  },
);

export const addOrganization = createAsyncThunk(
  'organization/addOrganization',
  async (data: OrganisationInput, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const organization = state?.organization?.organization;
      if (organization) {
        data.pk = organization.pk;
      }
      await service.addOrganization(data);
      dispatch(
        toastActions.addToast({
          title: 'Check email for OTP',
          status: 'success',
        }),
      );
      return data;
    } catch (err) {
      dispatch(
        toastActions.addToast({
          title: 'Failed to create organization',
          status: 'error',
        }),
      );
      throw err;
    }
  },
);

export const verifyOrganization = createAsyncThunk(
  'organization/verifyOrganization',
  async (data: OtpVerificationInput, { dispatch }) => {
    try {
      const organization = await service.verifyOrganization(data);
      dispatch(
        toastActions.addToast({
          title: 'Organization created',
          status: 'success',
        }),
      );
      return organization;
    } catch (err) {
      dispatch(
        toastActions.addToast({
          title: 'Invalid OTP',
          status: 'error',
        }),
      );
      throw err;
    }
  },
);

export const initialOrganizationState: OrganizationState = {
  isOrganizationsBeingFetched: false,
  isVerificationOtpSend: false,
  isOrganizationBeingAdded: false,
};

const organizationSlice = createSlice({
  name: ORGANIZATION_FEATURE_KEY,
  initialState: initialOrganizationState,
  reducers: {
    goBack(state) {
      state.isVerificationOtpSend = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getOrganizations.pending, state => {
      state.isOrganizationsBeingFetched = true;
    });
    builder.addCase(getOrganizations.fulfilled, (state, { payload }) => {
      state.isOrganizationsBeingFetched = false;
      state.organization = payload;
    });
    builder.addCase(getOrganizations.rejected, state => {
      state.isOrganizationsBeingFetched = false;
    });
    builder.addCase(addOrganization.pending, state => {
      state.isOrganizationBeingAdded = true;
    });
    builder.addCase(addOrganization.fulfilled, (state, { payload }) => {
      state.isOrganizationBeingAdded = false;
      state.isVerificationOtpSend = true;
      state.email = payload.email;
      state.name = payload.name;
    });
    builder.addCase(addOrganization.rejected, state => {
      state.isOrganizationBeingAdded = false;
    });
    builder.addCase(verifyOrganization.pending, state => {
      state.isOrganizationBeingAdded = true;
    });
    builder.addCase(verifyOrganization.fulfilled, (state, { payload }) => {
      state.isOrganizationBeingAdded = false;
      state.organization = payload;
    });
    builder.addCase(verifyOrganization.rejected, state => {
      state.isOrganizationBeingAdded = false;
    });
    builder.addCase(signOut, () => {
      return initialOrganizationState;
    });
  },
});

export const organizationReducer = organizationSlice.reducer;
export const orgActions = organizationSlice.actions;
export const getCurrentOrganization = (rootState: RootState): Organization => {
  const { organization } = rootState[ORGANIZATION_FEATURE_KEY];
  return organization!;
};
