import { HtmlHTMLAttributes, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Mixpanel } from 'mixpanel';

import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch, useSelector } from 'reduxStore/hooks';

import Button from 'components/button/button';
import Spinner from 'components/spinner/spinner';

import LoginImage from 'images/Login.png';

import { TextInput } from '../../components/form';
import Message from '../../components/message/message';
import { addOrganization } from '../../reduxStore/organization/organization.slice';
import { OrganisationInput } from '../../types';

import style from './addOrganization.module.scss';

type AddOrganizationProps = HtmlHTMLAttributes<HTMLDivElement>;

const orgSchema = yup
  .object({
    name: yup.string().required('Name is required'),
    email: yup
      .string()
      .required('Email is required')
      .email('Wrong Email address.'),
    agreeTerm: yup
      .boolean()
      .isTrue('Please agree to the Terms of Service and Privacy Policy'),
  })
  .required();

export default function AddOrganization(htmlProps: AddOrganizationProps) {
  const { isOrganizationBeingAdded, email, name } = useSelector(
    state => state.organization,
  );
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<OrganisationInput>({
    resolver: yupResolver(orgSchema),
    values: {
      email: email ?? '',
      name: name ?? '',
      agreeTerm: !!email,
    },
  });
  const errorMessage = useMemo(() => {
    if (!Object.keys(errors).length) {
      return null;
    }
    return Object.values(errors)?.[0]?.message || 'Some fields are missing';
  }, [errors]);
  const onSubmit = (data: OrganisationInput) => {
    Mixpanel.track('dev-portal add-org-btn', {
      button: 'Add an organization',
    });
    dispatch(addOrganization(data));
  };
  return (
    <div className={style.addOrganization} {...htmlProps}>
      <div className={style.content} {...htmlProps}>
        <div className={style.headerSection}>
          <div className={style.headerTitle}>
            Enter your Organization Details
          </div>
          <div className={style.headerSubtitle}>
          </div>
        </div>
        <img
          src={LoginImage}
          style={{ marginBottom: '25px' }}
          width="109"
          height="131"
          role="presentation"
          alt=""
        />
        <form onSubmit={handleSubmit(onSubmit)} data-testid="submission-form">
          {errorMessage ? <Message>{errorMessage}</Message> : null}
          <TextInput
            showClear={Boolean(watch('name'))}
            label="Organization Name:"
            required={true}
            register={register('name')}
            error={errors.name}
            placeholder="Organization Name"
            onClear={() => setValue('name', '')}
          />
          <TextInput
            showClear={Boolean(watch('email'))}
            label="Email:"
            register={register('email')}
            required={true}
            error={errors.email}
            placeholder="Email"
            onClear={() => setValue('email', '')}
          />
          <div className={style.checkBox}>
            <Controller
              name="agreeTerm"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <label className={style.checkBox}>
                  <input
                    className={style.inputCheckbox}
                    type="checkbox"
                    {...field}
                    value={undefined} // you can add this to ensure you're not passing the value attribute
                    checked={field.value} // this is the important part
                  />
                  <span className={style.checkmark}>
                    I agree to the{' '}
                    <a
                      className={style.a}
                      href="https://www.collab.land/terms-of-service"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                      className={style.a}
                      href="https://www.collab.land/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </span>
                </label>
              )}
            />
          </div>
          <Button
            type="submit"
            className={style.addButton}
            data-testid="add-button"
          >
            Submit
          </Button>
          <Spinner
            fullPage
            show={isOrganizationBeingAdded}
            data-testid="loading-spinner"
          />
        </form>
      </div>
    </div>
  );
}
