import cx from 'classnames';

import style from './buttonClassNames.module.scss';

export type ButtonPrimitiveProps = {
  className?: string;
  outline?: boolean;
  fullWidth?: boolean;
  color?: 'primary' | 'secondary' | 'danger';
  size?: 'small' | 'medium';
};

export default function buttonClassNames({
  outline: isOutlined,
  fullWidth: isFullWidth,
  color = 'primary',
  size = 'medium',
  className,
}: ButtonPrimitiveProps) {
  const classNames = cx(
    style.button,
    style[color],
    style[size],
    { [style.outline]: isOutlined },
    { [style.fullWidth]: isFullWidth },
    className,
  );

  return classNames;
}
