import { HtmlHTMLAttributes, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch, useSelector } from 'reduxStore/hooks';

import Button from 'components/button/button';
import Spinner from 'components/spinner/spinner';

import EnvelopeImage from 'images/envelope.png';

import { TextInput } from '../../components/form';
import Message from '../../components/message/message';
import {
  addOrganization, orgActions,
  verifyOrganization
} from "../../reduxStore/organization/organization.slice";
import { OtpVerificationInput } from '../../types';

import style from './addOrganization.module.scss';

type VerifyOrganizationProps = HtmlHTMLAttributes<HTMLDivElement>;

const otpSchema = yup
  .object({
    otp: yup.string().required('Otp is required'),
  })
  .required();
export default function VerifyOrganization(htmlProps: VerifyOrganizationProps) {
  const { isOrganizationBeingAdded, email, name } = useSelector(state => state.organization);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<OtpVerificationInput>({
    resolver: yupResolver(otpSchema),
  });
  const errorMessage = useMemo(() => {
    if (!Object.keys(errors).length) {
      return null;
    }
    return Object.values(errors)?.[0]?.message || 'Some fields are missing';
  }, [errors]);
  const onSubmit = (data: OtpVerificationInput) => {
    dispatch(verifyOrganization(data));
  };

  const onCancel = () => {
    dispatch(orgActions.goBack());
  };


  const onResend = () => {
    if(email && name) {
      dispatch(addOrganization({
        email,
        name: name
      }));
    }
  };


  return (
    <div className={style.addOrganization} {...htmlProps}>
      <div className={style.contentVerifyOrg} {...htmlProps}>
      <div className={style.frame}>
      <div className={style.textWrapper}>Verify your Email address</div>
      <p className={style.div}>A one time verification code has been sent to {email} please enter it below to continue</p>
    </div>
        <img
          src={EnvelopeImage}
          style={{ marginBottom: '3px' }}
          width="119"
          height="131"
          role="presentation"
          alt=""
        />
        <form onSubmit={handleSubmit(onSubmit)} data-testid="submission-form">
          {errorMessage ? <Message>{errorMessage}</Message> : null}
          <TextInput
            showClear={Boolean(watch('otp'))}
            label="One time verification code:"
            required={true}
            register={register('otp')}
            error={errors.otp}
            placeholder="One time verification code"
            onClear={() => setValue('otp', '')}
          />
          <Button
            type="submit"
            className={style.verifyButton1}
            data-testid="add-button"
          >
            Submit
          </Button>
          <Button
            type="button"
            onClick={onResend}
            className={style.verifyButton}
            data-testid="add-button"
          >
            Resend OTP
          </Button>
          <Button
            type="button"
            onClick={onCancel}
            className={style.verifyButton}
            data-testid="add-button"
          >
            Go back
          </Button>
          <Spinner
            fullPage
            show={isOrganizationBeingAdded}
            data-testid="loading-spinner"
          />
        </form>
      </div>
    </div>
  );
}
