import { PropsWithChildren } from 'react';
import { FieldError } from 'react-hook-form';

import cx from 'classnames';

import { CloseIcon } from 'icons/icons';

import { Text } from 'components/text/text';

import style from './inputBase.module.scss';

export type InputBaseProps = {
  label?: string;
  error?: FieldError;
  required?: boolean;
  showClear?: boolean;
  onClear?: () => void;
};

export default function InputBase({
  showClear,
  children,
  label,
  required,
  error,
  onClear,
}: InputBaseProps & PropsWithChildren) {
  return (
    <>
      <label className={style.label}>
        {label ? (
          <Text bold size="small" className={style.labelText}>
            {label} {required && '*'}
          </Text>
        ) : null}
        <div
          className={cx(style.inputWrapper, {
            [style.error]: Boolean(error),
          })}
          data-testid="input-wrapper"
        >
          {children}
          {showClear ? (
            <button
              className={style.clearButton}
              type="button"
              onClick={() => onClear?.()}
              data-testid="clear-button"
            >
              <CloseIcon />
            </button>
          ) : null}
        </div>
      </label>
    </>
  );
}
