import { PropsWithChildren } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import InputBase, { InputBaseProps } from './inputBase';

import style from './inputBase.module.scss';

type SelectProps = InputBaseProps &
  PropsWithChildren & {
    register: UseFormRegisterReturn;
  };

export default function Select({ register, children, ...props }: SelectProps) {
  return (
    <InputBase {...props}>
      <select className={style.input} {...register}>
        {children}
      </select>
    </InputBase>
  );
}
