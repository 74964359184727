import { Routes, Route } from 'react-router-dom';

import ClientAppApplication from './clientAppApplication';

export default function Consumer() {
  return (
    <Routes>
      <Route path="/client-app/add" element={<ClientAppApplication />} />
      <Route path="/client-app/:clientId/edit" element={<ClientAppApplication />} />
    </Routes>
  );
}
