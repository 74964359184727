import { PropsWithChildren } from 'react';

import cx from 'classnames';

import Button from 'components/button/button';

import style from './priceCard.module.scss';

export type PriceCardProps = {
  variant: 'free' | 'paid' | 'enterprise';
  name: string;
  activeTier?: boolean;
  title: string;
  className?: string;
  actionButton?: {
    label: string;
    disabled?: boolean;
    onClick: () => void;
  };
};

export default function PriceCard({
  variant,
  name,
  title,
  activeTier,
  className,
  actionButton,
  children,
}: PropsWithChildren<PriceCardProps>) {
  const classNames = cx(style.card, style[variant], className, style.cardLink);
  return (
    <a
      href="https://docs.collab.land/docs/downstream-integrations/api/rate-limiting/"
      className={classNames}
      data-testid="price-card"
    >
      <div className={style.name}>
        <span>{name}</span>
      </div>
      <div className={style.title}>
        <h2>{title}</h2>
      </div>
      {activeTier && <div className={style.activeTier}>Active tier</div>}
      <div className={style.features}>{children}</div>
      <div className={style.actionButton}>
        {actionButton && (
          <Button
            outline
            disabled={actionButton.disabled}
            onClick={e => {
              e.preventDefault();
              actionButton.onClick();
            }}
          >
            {actionButton.label}
          </Button>
        )}
      </div>
      {/*
      TODO: delete this button if not needed
      <div className={style.learnMoreLink}>
        <a className={style.link} href="#0">
          Learn more
        </a>
      </div>
      */}
    </a>
  );
}
