import { useState } from 'react';

import { Mixpanel } from 'mixpanel';

import useSubmissionLimitations from 'hooks/useSubmissionLimitations';

import Button from 'components/button/button';
import Link from 'components/button/linkButton';
import Modal from 'components/modal/modal';
import { Text } from 'components/text/text';

import style from './submissionLimitsGuardedButton.module.scss';

type Props = {
  appType: 'consumer' | 'contributor';
  label: string;
  to: string;
};

export default function SubmissionLimitsGuardedButton({
  appType,
  label,
  to,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isTotalSubmissionAtMax } = useSubmissionLimitations(appType);
  let isSubmissionAllowed = !isTotalSubmissionAtMax;

  if (!isSubmissionAllowed) {
    return (
      <>
        <Button outline size="small" onClick={() => setIsModalOpen(true)}>
          {label}
        </Button>
        <Modal
          open={isModalOpen}
          className={style.Modal}
          data-testid="submission-limit-modal"
        >
          <h2>All Client apps have been generated</h2>
          <Text size="large">
            <>
              All client apps have been generated, to create a new client app
              please upgrade to Paid Tier in the Pricing page
            </>
          </Text>
          <div className={style.actions}>
            <Button outline size="small" onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
            <Link outline size="small" to="/pricing">
              Go to pricing page
            </Link>
          </div>
        </Modal>
      </>
    );
  }

  return (
    <Link
      outline
      size="small"
      to={to}
      onClick={() => {
        Mixpanel.track(
          `dev-portal ${
            label === 'Submit Application'
              ? 'submit-app-btn'
              : 'request-api-btn'
          }`,
          {
            button: label,
          },
        );
      }}
    >
      {label}
    </Link>
  );
}
