import { useParams } from 'react-router-dom';

import useSignInUrl from 'hooks/useSignInUrl';

import Button from 'components/button/button';

import { Mixpanel } from '../../mixpanel';

export default function MixPanelButton() {
  const signInUrl = useSignInUrl();
  const { unique_code } = useParams();
  return (
    <div className="flex justify-center md:justify-start">
      <a href={signInUrl} data-testid="signin-link">
        <Button
          className="mt-4 md:mt-6 px-6 md:px-8 py-3 md:py-4 rounded-full font-bold tracking-wide"
          onClick={() => {
            Mixpanel.track('dev-portal sign-in btn-click', {
              page: unique_code ? 'invite' : 'signin',
              referral: unique_code ?? false,
            });
          }}
        >
          Get Started{' '}
          <svg
            className="w-3 h-3 md:w-3.5 md:h-3.5 ml-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </Button>
      </a>
    </div>
  );
}
