import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';



import { authenticateUser } from 'reduxStore/auth/auth.slice';
import { useDispatch, useSelector } from 'reduxStore/hooks';


export default function useSignIn() {
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const authState = useSelector(state => state.auth);
  const organizationState = useSelector(state => state.organization);
  const dispatch = useDispatch();

  useEffect(() => {
    const fragment = location.hash.substring(1); // Get the fragment identifier excluding the "#"
    const urlAccessToken = new URLSearchParams(fragment).get('access_token');

    dispatch(authenticateUser({ token: urlAccessToken }));

    if (urlAccessToken) {
      setSearchParams(new URLSearchParams());
    }
    // eslint-disable-next-line
  }, []);

  return {
    isLoading:
      authState.isLoading || organizationState.isOrganizationsBeingFetched,
    isSignedIn: Boolean(authState.user),
  };
}