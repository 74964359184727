import { Routes, Route } from 'react-router-dom';

import MiniAppApplication from './miniAppApplication';

export default function Contributor() {
  return (
    <Routes>
      <Route path="/mini-app/add" element={<MiniAppApplication />} />
    </Routes>
  );
}
