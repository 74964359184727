import { PropsWithChildren } from 'react';

import cx from 'classnames';

import style from './message.module.scss';

type MessageProps = PropsWithChildren & {};

export default function Message({ children }: MessageProps) {
  const classNames = cx(style.message, style.error);
  return <div className={classNames}>{children}</div>;
}
