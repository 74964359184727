import { UseFormRegisterReturn } from 'react-hook-form';

import InputBase, { InputBaseProps } from './inputBase';

import style from './inputBase.module.scss';

type TextInputProps = InputBaseProps & {
  placeholder: string;
  register: UseFormRegisterReturn;
};

export default function TextInput({
  placeholder,
  register,
  ...props
}: TextInputProps) {
  return (
    <InputBase {...props}>
      <input placeholder={placeholder} className={style.input} {...register} />
    </InputBase>
  );
}
