import { PropsWithChildren } from 'react';

import cx from 'classnames';

import * as Dialog from '@radix-ui/react-dialog';

import Button from 'components/button/button';

import style from './modal.module.scss';

type ConfirmProps = PropsWithChildren<{
  title: string;
  description: string;
  onConfirm: () => void;
  color?: 'primary' | 'secondary' | 'danger';
}>;

export default function Confirm({
  title,
  description,
  color = 'primary',
  children,
  onConfirm,
}: ConfirmProps) {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay data-testid="mask-backdrop" className={style.overlay}>
          <Dialog.Content
            data-testid="modal-container"
            className={cx(style.content)}
          >
            <Dialog.Title>{title}</Dialog.Title>
            <Dialog.Description>
              {description} Do you want to proceed?
            </Dialog.Description>
            <div className={style.buttons}>
              <Dialog.Close asChild>
                <Button color="secondary">Cancel</Button>
              </Dialog.Close>
              <Dialog.Close asChild>
                <Button color={color} onClick={onConfirm}>
                  Proceed
                </Button>
              </Dialog.Close>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
