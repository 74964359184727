// export type TestimonialProps = {
//     name: string;
//     title: string;
//     description: string;
//     rating: number;
//     image: string;
// }
// export default function Testimonial({ testimonials }: { testimonials: TestimonialProps[] }) {
//     return (
//         <div className="container my-24 mx-auto md:px-6">
//             <section className="mb-32 text-center">
//                 <h2 className="mb-12 text-3xl font-bold">What developers are saying about us</h2>
//                 <div className="grid gap-x-6 md:grid-cols-3 lg:gap-x-12">
//                     {testimonials.map((testimonial, index) => (
//                         <div key={index} className="mb-12 md:mb-0">
//                             <div className="mb-6 flex justify-center">
//                                 <img src={testimonial.image} alt={testimonial.name}
//                                     className="w-32 rounded-full shadow-lg dark:shadow-black/20" />
//                             </div>
//                             <h5 className="mb-2 text-lg font-bold">{testimonial.name}</h5>
//                             <h6 className="mb-4 font-bold text-purple-300">
//                                 {testimonial.title}
//                             </h6>
//                             <p className="mb-4 text-lg">
//                                 {testimonial.description}
//                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="inline-block w-6 text-pink-500">
//                                     <path fill="currentColor"
//                                         d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
//                                 </svg>
//                             </p>
//                             <ul className="mb-0 flex justify-center">
//                                 {/* show stars based on testimonial rating */}
//                                 {[...Array(testimonial.rating)].map((e, i) => (
//                                     <li key={i}>
//                                         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-5 text-yellow-500 mb-8">
//                                             <path fill="currentColor"
//                                                 d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
//                                         </svg>
//                                     </li>
//                                 ))}
//                             </ul>
//                         </div>
//                     ))}
//                 </div>
//             </section>
//         </div>
//     )
// }
import styles from './devTestimonial.module.scss';

export type TestimonialProps = {
  name: string;
  title: string;
  description: string;
  rating: number;
  image: string;
};

export default function Testimonial({
  testimonials,
}: {
  testimonials: TestimonialProps[];
}) {
  return (
    // <div className={styles.testimonialcontainer}>
    //   <section className={styles.testimonialsection}>
    //     <h2 className={styles.testimonialtitle}>
    //       What developers are saying about us
    //     </h2>
    //     <div className={styles.testimonialgrid}>
    //       {testimonials.map((testimonial, index) => (
    //         <div key={index} className={styles.testimonialitem}>
    //           <div className={styles.testimonialimage}>
    //             <img
    //               src={testimonial.image}
    //               alt={testimonial.name}
    //               className={styles.testimonialimage}
    //             />
    //           </div>
    //           <h5 className={styles.testimonialname}>{testimonial.name}</h5>
    //           <h6 className={styles.testimonialtitleinner}>
    //             {testimonial.title}
    //           </h6>
    //           <p className={styles.testimonialdescription}>
    //             {testimonial.description}
    //           </p>
    //         </div>
    //       ))}
    //     </div>
    //   </section>
    // </div>
    <div className={styles.containercenter}>
      <section className={styles.testimonialsection}>
        <h2 className="mb-12 text-5xl font-bold">Developers ❤️ Us</h2>
        <div className={styles.testimonialcards}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className={styles.testimonialcard}>
              <div className={styles.testimonialimage}>
                <img src={testimonial.image} alt={testimonial.name} />
              </div>
              <h5 className={styles.testimonialname}>{testimonial.name}</h5>
              <h6 className={styles.testimonialtitle}>{testimonial.title}</h6>
              <p className={styles.testimonialdescription}>
                {testimonial.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
