import {
  ConsumerClientAppClaim,
  ConsumerClientAppInput,
  ConsumerClientAppScopeGroup,
} from 'types';

import { createSlice } from '@reduxjs/toolkit';

import * as service from 'services/organization.service';

import { createAsyncThunk } from '../reduxUtils';
import { toastActions } from '../toast/toast.slice';

export const CONSUMER_FEATURE_KEY = 'consumer';

export interface ConsumerState {
  isConsumersBeingFetched: boolean;
  isConsumerBeingAdded: boolean;
  claimRequests: ConsumerClientAppClaim[];
  scopes: ConsumerClientAppScopeGroup[];
}

export const getConsumerClientAppClaims = createAsyncThunk(
  'consumer/getConsumerClientAppClaims',
  async (organizationId: string) => {
    return service.getConsumerClientAppClaims(organizationId);
  },
);

export const addConsumerClientApp = createAsyncThunk(
  'consumer/clientApp',
  async (
    {
      organizationId,
      data,
    }: { organizationId: string; data: ConsumerClientAppInput },
    { dispatch },
  ) => {
    try {
      const clientApp = await service.addConsumerClientApp(
        organizationId,
        data,
      );
      dispatch(
        toastActions.addToast({
          title: 'Client app added',
          status: 'success',
        }),
      );
      return clientApp;
    } catch (err: any) {
      dispatch(
        toastActions.addToast({
          title: 'Failed  to add Client app',
          description: err.message,
          status: 'error',
        }),
      );
      throw err;
    }
  },
);

export const updateClientApp = createAsyncThunk(
  'contributor/updateClientApp',
  async ({ sk, data }: { sk: string; data: ConsumerClientAppInput }, { dispatch }) => {
    try {
      await service.updateConsumerClientApp(sk, data);

      dispatch(
        toastActions.addToast({
          title: 'Client app updated',
          status: 'success',
        }),
      );

      return { sk, data };
    } catch (err: any) {
      dispatch(
        toastActions.addToast({
          title: 'Failed to rename Client app',
          description: err.message,
          status: 'error',
        }),
      );
      throw err;
    }
  },
);

export const deleteClientApp = createAsyncThunk(
  'contributor/deleteClientApp',
  async (sk: string, { dispatch }) => {
    try {
      await service.deleteConsumerClientApp(sk);

      dispatch(
        toastActions.addToast({
          title: 'Client app deleted',
          status: 'success',
        }),
      );

      return sk;
    } catch (err: any) {
      dispatch(
        toastActions.addToast({
          title: 'Failed to delete Client app',
          description: err.message,
          status: 'error',
        }),
      );
      throw err;
    }
  },
);

export const resetClientAppKey = createAsyncThunk(
  'contributor/resetClientAppKey',
  async (sk: string, { dispatch }) => {
    try {
      const response = await service.resetConsumerClientAppKey(sk);

      return response;
    } catch (err: any) {
      dispatch(
        toastActions.addToast({
          title: 'Failed to reset Client app key',
          description: err.message,
          status: 'error',
        }),
      );
      throw err;
    }
  },
);

export const preloadScopes = createAsyncThunk(
  'consumer/preloadScopes',
  async () => {
    return service.fetchConsumerClientAppScopes();
  },
);

export const initialConsumerState: ConsumerState = {
  isConsumersBeingFetched: false,
  isConsumerBeingAdded: false,
  claimRequests: [],
  scopes: [],
};

const consumerSlice = createSlice({
  name: CONSUMER_FEATURE_KEY,
  initialState: initialConsumerState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getConsumerClientAppClaims.pending, state => {
      state.isConsumersBeingFetched = true;
    });
    builder.addCase(
      getConsumerClientAppClaims.fulfilled,
      (state, { payload }) => {
        state.isConsumersBeingFetched = false;
        state.claimRequests = payload;
      },
    );
    builder.addCase(getConsumerClientAppClaims.rejected, state => {
      state.isConsumersBeingFetched = false;
    });
    builder.addCase(addConsumerClientApp.pending, state => {
      state.isConsumerBeingAdded = true;
    });
    builder.addCase(addConsumerClientApp.fulfilled, (state, { payload }) => {
      state.isConsumerBeingAdded = false;
      state.claimRequests.push(payload);
    });
    builder.addCase(addConsumerClientApp.rejected, state => {
      state.isConsumerBeingAdded = false;
    });
    builder.addCase(preloadScopes.fulfilled, (state, { payload }) => {
      state.scopes = payload;
    });
    builder.addCase(updateClientApp.fulfilled, (state, { payload }) => {
      let clientApp = state.claimRequests.find(app => app.sk === payload.sk);

      if (clientApp) {
        clientApp = {
          ...clientApp,
          ...payload
        }
      }
    });
    builder.addCase(deleteClientApp.fulfilled, (state, { payload }) => {
      const miniAppIndex = state.claimRequests.findIndex(
        app => app.sk === payload,
      );

      state.claimRequests.splice(miniAppIndex, 1);
    });
  },
});

export const consumerReducer = consumerSlice.reducer;
export const consumerActions = consumerSlice.actions;
