import cx from 'classnames';
import { Mixpanel } from 'mixpanel';

import { useSelector } from 'reduxStore/hooks';
import { getCurrentOrganization } from 'reduxStore/organization/organization.slice';

import Link from 'components/button/linkButton';
import { SectionBody } from 'components/section/section';

import style from './planInfo.module.scss';

/**
 *
 * This component is not going to be used for now
 function Bar({ planDetail }: { planDetail: PlanDetail }) {
  const totalReq = 10;
  const reqPerMonth = planDetail.rateLimits.reqPerMonth.duration;

  return (
    <div className={style.graph}>
      <div className={style.bar}>
        <div
          className={style.used}
          style={{ width: `${(100 / reqPerMonth) * totalReq}%` }}
        />
      </div>
      <div>
        {totalReq}/{reqPerMonth}
      </div>
    </div>
  );
}
 */

export default function PlanInfo() {
  const { planDetail } = useSelector(getCurrentOrganization);
  const classNames = cx(style.section, {
    [style.free]: planDetail.sk === 'DEFAULT',
    [style.paid]: planDetail.sk === 'PAID',
  });

  const rateLimit = planDetail.rateLimits
    .filter(k => !k.hide)
    .map(rateLimit => {
      return {
        description: rateLimit.label,
        value: rateLimit.points,
      };
    });
  const quotas = [
    ...rateLimit,
    {
      description: 'Max marketplace miniapps',
      value: planDetail.maxMiniApps,
    },
    {
      description: 'Max client apps',
      value: planDetail.maxAPIKeys,
    },
  ];

  return (
    <SectionBody className={classNames}>
      <div className={style.container}>
        <div className={style.description}>
          Rate limit refers to the maximum number of requests per user for a
          month.
        </div>
        <div className={style.detail}>
          {/*<Bar planDetail={planDetail} />*/}
          <div className={style.rateLimits}>
            {quotas.map(({ description, value }, index) => (
              <div key={index}>
                <div className={style.description}>{description}</div>
                <div>{value}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={style.title}>
          <h3>API rate limit</h3>
        </div>
        <div className={style.plan}>
          <div className={style.planName}>{planDetail.name}</div>
          <Link
            outline
            size="small"
            to="/pricing"
            onClick={() => {
              Mixpanel.track('dev-portal plan-upgrade-btn', {
                button: 'Upgrade',
              });
            }}
          >
            Upgrade
          </Link>
        </div>
      </div>
    </SectionBody>
  );
}
