import { CollabLandUser } from 'types';

import httpClient from './httpClient';

export function initialize(token: string | null) {
  if (!token) {
    token = localStorage.getItem('access_token');
  }
  if (!token) {
    throw new Error('Invalid token');
  }
  httpClient.setDefaultHeaders({
    authorization: `Bearer ${token}`,
  });
  localStorage.setItem('access_token', token);
}

export async function getUser(): Promise<CollabLandUser> {
  const payload = await httpClient('account/me');

  return payload;
}

export function signOut() {
  localStorage.clear();
}
