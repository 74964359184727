import { useParams } from 'react-router-dom';

import { useSelector } from 'reduxStore/hooks';

import useSignInUrl from 'hooks/useSignInUrl';

import AppHero from 'components/appHero/appHero';
import Button from 'components/button/button';
import ChainCarouselLeft from 'components/chainCarousel/ChainCarouselLeft';
import ChainCarouselRight from 'components/chainCarousel/ChainCarouselRight';
import CommsCard, { CardProps } from 'components/commsCard/commsCard';
import Testimonial from 'components/devTestimonial/devTestimonial';
import AppFooter from 'components/footer/footer';
import SupportedChains from 'components/supportedChains/supportedChains';
import TimeOut from 'components/timeOut/timeOut';

import blueBall from '../../images/Globe-Blue.png';
import yellowBall from '../../images/Globe-Yellow.png';
import redBall from '../../images/Red-Bolt.png';
import bossdragon from '../../images/bossdragon.png';
import brandon from '../../images/brandon.jpeg';
import dija from '../../images/dija.png';
import memebrains from '../../images/memebrains.png';
import vraj from '../../images/vraj.png';
import { Mixpanel } from '../../mixpanel';

import style from './signin.module.scss';

export default function SignIn() {
  const signInUrl = useSignInUrl();
  const { unique_code } = useParams();
  const { isTokenExpired } = useSelector(state => state.auth);

  const comcard: CardProps[] = [
    {
      variant: 'variant2',
      thumbnail: blueBall,
      title: 'Contribute to the Miniapp marketplace',
      description:
        'Build Miniapps for the Collab.Land Marketplace to showcase your product to our expansive network of over 50k communities.',
      url: 'https://login.collab.land/?redirect_uri=https://dev-portal.collab.land/',
    },
    {
      variant: 'variant3',
      thumbnail: yellowBall,
      title: 'Request API Access',
      description:
        'Build with the Collb.Land Community APIs to create custom token gating experiences anywhere.',
      url: '',
      withButton: true,
    },
    {
      variant: 'variant4',
      thumbnail: redBall,
      title: 'Port your Community',
      description:
        'Expand beyond Discord and Telegram! Enhance engagement and offer broader access to your community through games, websites or mobile apps using our Community APIs.',
    },
  ];

  const testimonials = [
    {
      name: 'Diraj T.',
      title: 'Founding Software Engineer @MarkX',
      rating: 5,
      description:
        'The Collab Action templates made things so much simpler. Within just a couple of minutes, I was able to clone the template onto my local machine and see all the commands in action. \n\nThe API documentation is on point.',
      feedback: 'You guys are doing great!',
      image: dija,
    },
    {
      name: 'Bhargav Patel',
      title: 'CTO @NodeBlocks',
      rating: 5,
      description:
        'Collab.Land has a really good documentation to get started with building MiniApps. We were able to create the whole MiniApp within a few hours!',
      feedback:
        'For submitting the MiniApp, there is a bit of clarity needed regarding how to submit. I think if there was additional documentation added to the docs website, it would be really helpful for future devs.',
      image: bossdragon,
    },
    {
      name: 'Brandon Straza',
      title: 'Founder, TheSuccessFinder',
      rating: 5,
      description:
        "One of the major advantages of integrating with Collab.Land was eliminating workarounds. With their token gating service, users either have the required token or they don't, which ensures a secure and streamlined access restriction process for us.",
      feedback: '',
      image: brandon,
    },
    {
      name: 'Vraj Desai',
      title: 'DevRel @Huddle01',
      rating: 4,
      description:
        'I like the support from Collab.Land, they are always helpful and ready to answer my queries. ',
      feedback: 'Documentation could be improved. ',
      image: vraj,
    },
    {
      name: 'MemeBrains',
      title: 'Founder | Buidler | Podcaster',
      rating: 5,
      description:
        'The dev support team at Collab.Land are very responsive and helpful. This makes it easy to find answers and enables me to focus on what matters most; buidling cool new products. ',
      image: memebrains,
    },
  ];

  const ctaButton = (
    <a href={signInUrl} data-testid="signin-link">
      <Button
        className="mt-6 mb-6 px-8 py-4 rounded-full font-bold tracking-wide"
        onClick={() => {
          Mixpanel.track('dev-portal sign-in btn-click', {
            page: unique_code ? 'invite' : 'signin',
            referral: unique_code ?? false,
          });
        }}
      >
        Get Started{' '}
        <svg
          className="w-3.5 h-3.5 ml-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 10"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 5h12m0 0L9 1m4 4L9 9"
          />
        </svg>
      </Button>
    </a>
  );

  return (
    <div className={style.signin}>
      <AppHero ctaButton={ctaButton} />
      {isTokenExpired ? <TimeOut /> : null}
      <div className={style.cards} data-testid="info-cards">
        {comcard.map((card, index) => (
          <CommsCard key={index} {...card} />
        ))}
      </div>
      <ChainCarouselLeft />
      <ChainCarouselRight />
      <SupportedChains />
      <Testimonial testimonials={testimonials} />
      <AppFooter />
    </div>
  );
}
