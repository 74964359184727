import { CopyIcon } from 'icons/icons';

import Button from 'components/button/button';
import { Text } from 'components/text/text';

import style from './copyApiKey.module.scss';
import { ConsumerClientAppClaim } from "../../types";

type CopyApiKeyProps = {
  secret?: string | null;
  clientApp: ConsumerClientAppClaim;
  isNew?: boolean;
  onCopy: (data: string) => void;
};

export default function CopyApiKey({
  clientApp,
  isNew,
  onCopy,
}: CopyApiKeyProps) {
  return (
    <div>
      <h2>
        {isNew
          ? 'Your client application has been created'
          : 'Your client application has been updated'}
      </h2>
      <Text size="large">
        Please save this secret keys somewhere safe and accessible. For security
        reasons you{' '}
        <Text bold tag="span">
          won't be able to view it
        </Text>{' '}
        through your developer portal again. If you lose this secret key, you’ll
        need to generate a new one.
      </Text>
      <p className={style.description}></p>
      <label className={style.label}>
        <Text bold size="small" className={style.labelText}>
          Client Id
        </Text>
        <div className={style.control}>
          <input readOnly className={style.input} value={clientApp.id ?? ''} />
          <Button
            color="secondary"
            onClick={() => onCopy(clientApp.id!)}
            className={style.copyButton}
          >
            <CopyIcon />
          </Button>
        </div>
      </label>
      <label className={style.label}>
          <Text bold size="small" className={style.labelText}>
            Client Secret
          </Text>
          <div className={style.control}>
            <input readOnly className={style.input} value={clientApp.clientSecret ?? ''} />
            <Button
              color="secondary"
              onClick={() => onCopy(clientApp.clientSecret)}
              className={style.copyButton}
            >
              <CopyIcon />
            </Button>
          </div>
        </label>
      <label className={style.label}>
        <Text bold size="small" className={style.labelText}>
          API Key
        </Text>
        <div className={style.control}>
          <input readOnly className={style.input} value={clientApp.apiKey ?? ''} />
          <Button
            color="secondary"
            onClick={() => onCopy(clientApp.apiKey)}
            className={style.copyButton}
          >
            <CopyIcon />
          </Button>
        </div>
      </label>
    </div>
  );
}
