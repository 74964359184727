import React, { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import buttonClassNames, { ButtonPrimitiveProps } from './buttonClassNames';

type ButtonProps = PropsWithChildren & LinkProps & ButtonPrimitiveProps;

export default function LinkButton({
  children,
  outline,
  color = 'primary',
  size = 'medium',
  className,
  ...props
}: ButtonProps) {
  const classNames = buttonClassNames({ outline, color, size, className });
  return (
    <Link className={classNames} {...props}>
      {children}
    </Link>
  );
}
