import { PropsWithChildren } from 'react';

import cx from 'classnames';

import * as Dialog from '@radix-ui/react-dialog';

import style from './modal.module.scss';

type ModalProps = PropsWithChildren & {
  open: boolean;
  className?: string;
  enforce?: boolean;
  onDismiss?: () => void;
};

export default function Modal({
  enforce,
  open,
  onDismiss,
  className,
  children,
}: ModalProps) {
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay
          data-testid="mask-backdrop"
          className={style.overlay}
          onClick={() => {
            if (enforce) {
              return;
            }
            if (onDismiss) {
              onDismiss();
            }
          }}
        >
          <Dialog.Content
            data-testid="modal-container"
            className={cx(style.content, className)}
          >
            {children}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
