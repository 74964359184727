import { ContributorMiniAppClaim, ContributorMiniAppInput } from 'types';

import { createSlice } from '@reduxjs/toolkit';

import * as service from 'services/organization.service';

import { createAsyncThunk } from '../reduxUtils';
import { toastActions } from '../toast/toast.slice';

export const CONTRIBUTOR_FEATURE_KEY = 'contributor';

export interface ContributorState {
  isContributorsBeingFetched: boolean;
  isContributorBeingAdded: boolean;
  claimRequests: ContributorMiniAppClaim[];
}

export const getContributorMiniAppClaims = createAsyncThunk(
  'contributor/getContributorMiniAppClaims',
  async (organizationId: string) => {
    return service.getContributorMiniAppClaims(organizationId);
  },
);

export const addContributorMiniApp = createAsyncThunk(
  'contributor/miniApp',
  async (
    {
      organizationId,
      data,
    }: { organizationId: string; data: ContributorMiniAppInput },
    { dispatch },
  ) => {
    try {
      const miniApp = await service.addContributorMiniApp(organizationId, data);
      dispatch(
        toastActions.addToast({
          title: 'Mini app added',
          status: 'success',
        }),
      );
      return miniApp;
    } catch (err: any) {
      dispatch(
        toastActions.addToast({
          title: 'Failed to add Mini app',
          description: err.message,
          status: 'error',
        }),
      );
      throw err;
    }
  },
);

export const renameMiniApp = createAsyncThunk(
  'contributor/renameMiniApp',
  async ({ sk, newName }: { sk: string; newName: string }, { dispatch }) => {
    try {
      await service.renameContributorMiniApp(sk, newName);

      dispatch(
        toastActions.addToast({
          title: 'Mini app renamed',
          status: 'success',
        }),
      );

      return { sk, newName };
    } catch (err: any) {
      dispatch(
        toastActions.addToast({
          title: 'Failed to rename Mini app',
          description: err.message,
          status: 'error',
        }),
      );
      throw err;
    }
  },
);

export const deleteMiniApp = createAsyncThunk(
  'contributor/deleteMiniApp',
  async (sk: string, { dispatch }) => {
    try {
      await service.deleteContributorMiniApp(sk);

      dispatch(
        toastActions.addToast({
          title: 'Mini app deleted',
          status: 'success',
        }),
      );

      return sk;
    } catch (err: any) {
      dispatch(
        toastActions.addToast({
          title: 'Failed to delete Mini app',
          description: err.message,
          status: 'error',
        }),
      );
      throw err;
    }
  },
);

export const resetMiniAppKey = createAsyncThunk(
  'contributor/resetMiniAppKey',
  async (sk: string, { dispatch }) => {
    try {
      const response = await service.resetContributorMiniAppKey(sk);

      dispatch(
        toastActions.addToast({
          title: 'Mini app key reset',
          status: 'success',
        }),
      );

      return response;
    } catch (err: any) {
      dispatch(
        toastActions.addToast({
          title: 'Failed to reset Mini app key',
          description: err.message,
          status: 'error',
        }),
      );
      throw err;
    }
  },
);

export const initialContributorState: ContributorState = {
  isContributorsBeingFetched: false,
  isContributorBeingAdded: false,
  claimRequests: [],
};

const contributorSlice = createSlice({
  name: CONTRIBUTOR_FEATURE_KEY,
  initialState: initialContributorState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getContributorMiniAppClaims.pending, state => {
      state.isContributorsBeingFetched = true;
    });
    builder.addCase(
      getContributorMiniAppClaims.fulfilled,
      (state, { payload }) => {
        state.isContributorsBeingFetched = false;
        state.claimRequests = payload;
      },
    );
    builder.addCase(getContributorMiniAppClaims.rejected, state => {
      state.isContributorsBeingFetched = false;
    });
    builder.addCase(addContributorMiniApp.pending, state => {
      state.isContributorBeingAdded = true;
    });
    builder.addCase(addContributorMiniApp.fulfilled, (state, { payload }) => {
      state.isContributorBeingAdded = false;
      state.claimRequests.push(payload);
    });
    builder.addCase(addContributorMiniApp.rejected, state => {
      state.isContributorBeingAdded = false;
    });
    builder.addCase(renameMiniApp.fulfilled, (state, { payload }) => {
      const miniApp = state.claimRequests.find(app => app.sk === payload.sk);

      if (miniApp) {
        miniApp.name = payload.newName;
      }
    });
    builder.addCase(deleteMiniApp.fulfilled, (state, { payload }) => {
      const miniAppIndex = state.claimRequests.findIndex(
        app => app.sk === payload,
      );

      state.claimRequests.splice(miniAppIndex, 1);
    });
    builder.addCase(resetMiniAppKey.fulfilled, (state, { payload }) => {
      const miniAppIndex = state.claimRequests.findIndex(
        app => app.sk === payload.sk,
      );

      state.claimRequests.splice(miniAppIndex, 1, payload);
    });
  },
});

export const contributorReducer = contributorSlice.reducer;
export const contributorActions = contributorSlice.actions;
