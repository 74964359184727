import { useState } from 'react';

import Button from 'components/button/button';

import styles from './footer.module.scss';

export default function AppFooter() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleNewsletterSubmission = async (e: any) => {
    e.preventDefault();
    console.log('newsletter submitted');

    const formData = {
      name: name,
      email: email,
    };

    const data = {
      fields: {
        Name: formData.name,
        Email: formData.email,
      },
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(
        'https://api.airtable.com/v0/appqFByLhGmjYJP0I/tbljigH3UMHU9NpvU',
        requestOptions,
      );

      localStorage.setItem('newsletterFormData', JSON.stringify(response));
      setName('');
      setEmail('');
    } catch (error) {
      console.error('Error submitting to Airtable:', error);
    }
  };

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <div className={styles.content}>
          <div className={styles.containerinner}>
            <div className={styles.gridcontainer}>
              <div className={styles.griditem}>
                <h1 className={styles.header}>
                  Don't miss <br />
                  <span className={styles.yellowtext}>any updates</span>
                </h1>
                <p className={styles.leadtext}>
                  We will only share useful content.
                </p>
              </div>
              <div className={styles.griditem}>
                <div className={styles.formcontainer}>
                  <h2 className={styles.formheader}>
                    Subscribe to our newsletter
                  </h2>
                  <form>
                    <div className={styles.forminput}>
                      <input
                        type="text"
                        className={styles.inputfield}
                        id="name"
                        placeholder="Name"
                        value={name}
                        onChange={handleNameChange}
                      />
                    </div>
                    <div className={styles.forminput}>
                      <input
                        type="email"
                        className={styles.inputfield}
                        id="email"
                        placeholder="Email address"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </div>
                    <Button onClick={handleNewsletterSubmission}>
                      Subscribe
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
