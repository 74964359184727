import { useNavigate } from 'react-router-dom';

import { ContributorMiniAppInput } from 'types';

import { addContributorMiniApp } from 'reduxStore/contributor/contributor.slice';
import { useDispatch, useSelector } from 'reduxStore/hooks';
import { getCurrentOrganization } from 'reduxStore/organization/organization.slice';

import Spinner from 'components/spinner/spinner';

import { dashboardUrl } from '../../constants';
import MiniAppsSubmissionForm from './miniAppsSubmissionForm';

import style from './miniAppApplication.module.scss';

export default function MiniAppApplication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organizationId = useSelector(getCurrentOrganization).pk;
  const contributorState = useSelector(state => state.contributor);

  async function submitForm(data: ContributorMiniAppInput) {
    await dispatch(addContributorMiniApp({ organizationId, data })).unwrap();
    navigate(dashboardUrl);
  }

  const onCancel = () => navigate(dashboardUrl);

  return (
    <div className={style.miniAppApplication}>
      <h2 className={style.title}>Submit a Miniapp for the marketplace</h2>
      <MiniAppsSubmissionForm onSubmit={submitForm} onCancel={onCancel} />
      <Spinner
        fullPage
        show={contributorState.isContributorBeingAdded}
        data-testid="load-spinner"
      />
    </div>
  );
}
