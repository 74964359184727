import { useNavigate } from 'react-router-dom';

import Button from 'components/button/button';

import { dashboardUrl } from '../../constants';

import style from './pageNotFound.module.scss';

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className={style.pageNotFound}>
      <h2 className={style.title}>404 Page not found</h2>
      <div className={style.goBackButtonWrapper}>
        <Button outline onClick={() => navigate(dashboardUrl)}>
          Return to Dashboard
        </Button>
      </div>
    </div>
  );
}
