import cx from 'classnames';
import { Toast as ToastType } from 'types';

import * as ToastPrimitive from '@radix-ui/react-toast';

import { CloseIcon, ErrorIcon, SuccessIcon } from 'icons/icons';

import style from './toast.module.scss';

export type ToastProps = ToastType & {
  onClose: (id: number) => void;
};

export default function Toast({
  id,
  title,
  description,
  status,
  onClose,
}: ToastProps) {
  return (
    <ToastPrimitive.Root
      data-testid="toast"
      className={cx(style.toast, style[status])}
      onOpenChange={isOpen => {
        if (!isOpen) {
          onClose(id);
        }
      }}
    >
      <div className={style.titleRow}>
        <ToastPrimitive.Title className={style.title}>
          {status === 'success' ? (
            <SuccessIcon
              className={style.statusIcon}
              data-testid="success-icon"
            />
          ) : null}
          {status === 'error' ? (
            <ErrorIcon className={style.statusIcon} data-testid="error-icon" />
          ) : null}
          <div className={style.message} data-testid="title">
            {title}
          </div>
        </ToastPrimitive.Title>
        <ToastPrimitive.Close
          className={style.close}
          data-testid="close-button"
        >
          <CloseIcon />
        </ToastPrimitive.Close>
      </div>
      {description ? (
        <ToastPrimitive.Description className={style.description}>
          {description.split('\n').map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </ToastPrimitive.Description>
      ) : null}
    </ToastPrimitive.Root>
  );
}
