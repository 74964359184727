// import mixpanel from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser';

const env = process.env.REACT_APP_ENV;
const MIXPANEL_ENABLED = process.env.REACT_APP_MIXPANEL_ENABLED ?? false;
const MIXPANEL_ID = process.env.REACT_APP_MIXPANEL_ID ?? '';
mixpanel.init(MIXPANEL_ID, {
  debug: env === 'production' ? false : true,
  track_pageview: true,
  persistence: 'localStorage',
  ignore_dnt: true,
});

let actions = {
  identify: (id: string) => {
    if (MIXPANEL_ENABLED) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (MIXPANEL_ENABLED) mixpanel.alias(id);
  },
  track: (name: string, props?: { [key: string]: any }) => {
    if (MIXPANEL_ENABLED) mixpanel.track(name, props);
  },
  // people: {
  //   set: (props: {[key:string]: any}) => {
  //     mixpanel.people.set(props);
  //   },
  //   increment: (props: string | { [key:string]: number }, count?: number) => {
  //     if (env_check) mixpanel.people.set(props, count);
  //   }
  // },
};

export let Mixpanel = actions;
