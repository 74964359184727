import React, { PropsWithChildren } from 'react';

import cx from 'classnames';

import style from './text.module.scss';

type TextProps = PropsWithChildren & {
  tag?: 'p' | 'div' | 'span';
  bold?: boolean;
  size?: 'small' | 'large';
  className?: string;
};

export function Text({
  className,
  bold,
  size,
  tag = 'p',
  children,
}: TextProps) {
  const classNames = cx(
    {
      [style.bold]: bold,
      [style.small]: size,
    },
    size ? style[size] : null,
    className,
  );

  return React.createElement(tag, { className: classNames }, children);
}

export function Disclaimer({ children }: PropsWithChildren) {
  return (
    <Text size="small" className={style.disclaimer}>
      {children}
    </Text>
  );
}
