import { Wallet } from 'types';

import httpClient from './httpClient';

export async function fetchWallet(organizationId: string): Promise<Wallet> {
  const response = await httpClient(
    `dev-orgs/${encodeURIComponent(organizationId)}/wallet`,
  );

  return response;
}

export async function addWallet(organizationId: string): Promise<Wallet> {
  const response = await httpClient(
    `dev-orgs/${encodeURIComponent(organizationId)}/wallet`,
    {
      method: 'put',
    },
  );

  return response;
}
