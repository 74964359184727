import { useSelector } from 'reduxStore/hooks';
import { getCurrentOrganization } from 'reduxStore/organization/organization.slice';

export default function useSubmissionLimitations(
  type: 'consumer' | 'contributor',
) {
  const wallet = useSelector(state => state.wallet);
  const organization = useSelector(getCurrentOrganization);
  const consumer = useSelector(state => state.consumer);
  const contributor = useSelector(state => state.contributor);

  const submissionCount =
    type === 'consumer'
      ? consumer.claimRequests.length
      : contributor.claimRequests.length;

  const allowedCount =
    type === 'consumer'
      ? organization.planDetail.maxAPIKeys
      : organization.planDetail.maxMiniApps;

  return {
    isWalletExist: Boolean(wallet.wallet),
    isTotalSubmissionAtMax: submissionCount >= allowedCount,
  };
}
