import { HtmlHTMLAttributes } from 'react';

import cx from 'classnames';

import Mask from 'components/mask/mask';

import style from './spinner.module.scss';

export type SpinnerProps = HtmlHTMLAttributes<HTMLDivElement> & {
  show: boolean;
  fullPage?: boolean;
};

export default function Spinner({
  show,
  fullPage,
  ...htmlProps
}: SpinnerProps) {
  const LoadingSpinner = () => (
    <div
      className={cx(style.loader)}
      data-testid="loading-spinner"
      {...htmlProps}
    >
      Loading...
    </div>
  );
  if (fullPage) {
    return (
      <Mask show={show} data-testid="mask">
        <LoadingSpinner />
      </Mask>
    );
  }
  if (!show) {
    return null;
  }
  return <LoadingSpinner />;
}
