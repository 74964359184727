import { PropsWithChildren, ReactNode } from 'react';

import {
  AppSubmissionAction,
  ClientAppSubmissionStatus,
  MiniAppSubmissionStatus,
} from 'types';

import { Avatar } from '../../../components/Image/image';

import style from './credentialCard.module.scss';

export type CredentialCardProps = PropsWithChildren<{
  title: ReactNode;
  buttons: ReactNode;
  status: ClientAppSubmissionStatus | MiniAppSubmissionStatus;
  icon?: string;
  name: string;
  date: string;
  actions: AppSubmissionAction[];
  ID?: string;
}>;

function formatDate(isoDateStr: string) {
  // const dateTime = DateTime.fromISO(isoDateStr);
  return new Date(isoDateStr).toLocaleDateString();
  // return dateTime.toFormat('d.L.y');
}

function ApiStatus({
  status,
}: {
  status: ClientAppSubmissionStatus | MiniAppSubmissionStatus;
}) {
  let className;
  let apiStatus;
  if (['REJECTED', 'REVOKED'].includes(status)) {
    apiStatus = 'In-active';
    className = style.inactive;
  } else if (status === 'PENDING') {
    apiStatus = 'In-review';
  } else if (status === 'APPROVED') {
    apiStatus = 'Active';
    className = style.active;
  }

  return (
    <div className={style.apiStatus}>
      <div className={className}>{apiStatus}</div>
    </div>
  );
}

export default function CredentialCard({
  title,
  name,
  icon,
  status,
  buttons,
  children,
  date,
  ID,
}: CredentialCardProps) {
  return (
    <div className={style.container}>
      <Avatar imageUrl={icon} name={name}></Avatar>
      <div className={style.card}>
        <div className={style.title}>
          {title}
          {ID && <div className={style.id}>ID: {ID.split('#').pop()}</div>}
          <div className={style.date}>Created on {formatDate(date)}</div>
        </div>
        <ApiStatus status={status} />
        <div className={style.body}>{children}</div>
        <div className={style.buttons}>{buttons}</div>
      </div>
    </div>
  );
}
