import httpClient from './httpClient';

export async function fetchPlans() {
  const payload = await httpClient(`dev-orgs/plans`);

  return payload.items;
}

export async function subscribePlan(
  planId: string,
  {
    redirectUri,
    state = {},
  }: {
    redirectUri: string;
    state: Record<string, any>;
  },
): Promise<{ url: string }> {
  const payload = await httpClient(
    `unlock/subscription/${encodeURIComponent(planId)}`,
    {
      method: 'post',
      body: JSON.stringify({
        redirectUri,
        state,
      }),
    },
  );

  return payload;
}
