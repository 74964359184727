import { useParams } from 'react-router-dom';

import useSignInUrl from 'hooks/useSignInUrl';

import Button from 'components/button/button';

import { Mixpanel } from '../../mixpanel';

import styles from './commsCard.module.scss';

export default function CommsCard({
  variant,
  thumbnail,
  title,
  description,
  url,
  withButton = false,
}: CardProps) {
  const signInUrl = useSignInUrl();
  const { unique_code } = useParams();

  return (
    <div className={`${styles.commscard} ${withButton ? 'with-button' : ''}`}>
      <img alt={title} src={thumbnail} />
      <h5>{title}</h5>
      <p>{description}</p>
      {withButton && (
        <a href={signInUrl} data-testid="signin-link">
          <Button
            className="inline-flex items-center px-3 py-2"
            onClick={() => {
              Mixpanel.track('dev-portal sign-in btn-click', {
                page: unique_code ? 'invite' : 'signin',
                referral: unique_code ?? false,
              });
            }}
          >
            Get Started
            <svg
              className="w-3.5 h-3.5 ml-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </Button>
        </a>
      )}
    </div>
  );
}

export type CardProps = {
  variant?: string;
  thumbnail?: string;
  title: string;
  description: string;
  url?: string;
  withButton?: boolean;
};
