import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { preloadScopes } from 'reduxStore/consumer/consumer.slice';
import { useDispatch } from 'reduxStore/hooks';
import { preloadPlans } from 'reduxStore/plan/plan.slice';

export function Preload() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(preloadScopes());
    dispatch(preloadPlans());
  }, [dispatch]);

  return <Outlet />;
}

export default Preload;
