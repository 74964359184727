import { createSlice } from '@reduxjs/toolkit';

import * as service from 'services/plan.service';

import { createAsyncThunk } from '../reduxUtils';
import { RootState } from '../store';
import { toastActions } from '../toast/toast.slice';

export const PRICING_FEATURE_KEY = 'pricing';

export interface PricingState {
  loading: boolean;
}

const initialPricingState: PricingState = { loading: false };

export const subscribePlan = createAsyncThunk(
  'pricing/subscribePlan',
  async (
    {
      planId,
      organizationId,
      redirectUri,
    }: {
      planId: string;
      organizationId: string;
      redirectUri: string;
    },
    { dispatch },
  ) => {
    try {
      const state = {
        subscriberId: organizationId,
        type: 'dev-portal'
      }
      const unlockUrl = await service.subscribePlan(planId, {
        redirectUri,
        state
      });

      return unlockUrl;
    } catch (err: any) {
      dispatch(
        toastActions.addToast({
          title: 'Failed to subscribe',
          status: 'error',
          description: err.message,
        }),
      );

      throw err;
    }
  },
);

const pricingSlice = createSlice({
  name: PRICING_FEATURE_KEY,
  initialState: initialPricingState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(subscribePlan.pending, state => {
      state.loading = true;
    });
    builder.addCase(subscribePlan.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(subscribePlan.rejected, state => {
      state.loading = false;
    });
  },
});

export const pricingActions = pricingSlice.actions;
export const pricingReducer = pricingSlice.reducer;
export const getPricingState = (rootState: RootState): PricingState => {
  return rootState[PRICING_FEATURE_KEY];
};
