import {
  configureStore,
  combineReducers,
  PreloadedState,
} from '@reduxjs/toolkit';

import { AUTH_FEATURE_KEY, authReducer } from './auth/auth.slice';
import {
  CONSUMER_FEATURE_KEY,
  consumerReducer,
} from './consumer/consumer.slice';
import {
  CONTRIBUTOR_FEATURE_KEY,
  contributorReducer,
} from './contributor/contributor.slice';
import { errorHandleMiddleware } from './middlewares';
import {
  ORGANIZATION_FEATURE_KEY,
  organizationReducer,
} from './organization/organization.slice';
import { PLAN_FEATURE_KEY, planReducer } from './plan/plan.slice';
import { PRICING_FEATURE_KEY, pricingReducer } from './pricing/pricing.slice';
import { TOAST_FEATURE_KEY, toastReducer } from './toast/toast.slice';
import { WALLET_FEATURE_KEY, walletReducer } from './wallet/wallet.slice';

const rootReducer = combineReducers({
  [AUTH_FEATURE_KEY]: authReducer,
  [TOAST_FEATURE_KEY]: toastReducer,
  [WALLET_FEATURE_KEY]: walletReducer,
  [ORGANIZATION_FEATURE_KEY]: organizationReducer,
  [CONSUMER_FEATURE_KEY]: consumerReducer,
  [CONTRIBUTOR_FEATURE_KEY]: contributorReducer,
  [PLAN_FEATURE_KEY]: planReducer,
  [PRICING_FEATURE_KEY]: pricingReducer,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    // Additional middleware can be passed to this array
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(errorHandleMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
    // Optional Redux store enhancers
    enhancers: [],
    preloadedState,
  });
}

export const store = setupStore();

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
