import React, { PropsWithChildren, forwardRef } from 'react';

import buttonClassNames, { ButtonPrimitiveProps } from './buttonClassNames';

type ButtonProps = PropsWithChildren &
  React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonPrimitiveProps;

function Button(
  {
    children,
    outline,
    fullWidth,
    color = 'primary',
    size = 'medium',
    className,
    ...props
  }: ButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const classNames = buttonClassNames({
    outline,
    fullWidth,
    color,
    size,
    className,
  });

  return (
    <button className={classNames} {...props} ref={ref}>
      {children}
    </button>
  );
}

export default forwardRef<HTMLButtonElement, ButtonProps>(Button);
