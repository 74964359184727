import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Mixpanel } from 'mixpanel';
import { ContributorMiniAppInput } from 'types';
import * as yup from 'yup';
import { AnySchema } from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/button/button';
import { Select, TextArea, TextInput } from 'components/form';
import Message from 'components/message/message';
import { Disclaimer } from 'components/text/text';

import style from './miniAppsSubmissionForm.module.scss';

const schema = yup
  .object<Record<keyof ContributorMiniAppInput, AnySchema>>({
    name: yup.string().required('Name is required'),
    icon: yup.string().optional(),
    url: yup.string().required('URL is required'),
    feedback: yup.string().optional(),
    description: yup.string().required('Description is required'),
    platforms: yup.string().optional(),
  })
  .required();

export default function MiniAppsSubmissionForm({
  onCancel,
  onSubmit,
}: {
  onCancel: () => void;
  onSubmit: SubmitHandler<ContributorMiniAppInput>;
}) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ContributorMiniAppInput>({
    resolver: yupResolver(schema),
  });

  const errorMessage = useMemo(() => {
    if (!Object.keys(errors).length) {
      return null;
    }
    return Object.values(errors)?.[0]?.message || 'Some fields are missing';
  }, [errors]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="submission-form">
      {errorMessage ? <Message>{errorMessage}</Message> : null}
      <TextInput
        showClear={Boolean(watch('name'))}
        required={true}
        label="Application Name"
        register={register('name')}
        error={errors.name}
        placeholder="Application's name"
        onClear={() => setValue('name', '')}
      />
      <TextInput
        showClear={Boolean(watch('url'))}
        required={true}
        label="Action URL"
        register={register('url')}
        error={errors.url}
        placeholder="URL"
        onClear={() => setValue('url', '')}
      />
      <TextInput
        showClear={Boolean(watch('icon'))}
        label="Icon URL"
        register={register('icon')}
        error={errors.icon}
        placeholder="Icon"
        onClear={() => setValue('icon', '')}
      />
      <TextArea
        showClear={Boolean(watch('description'))}
        label="Describe your app"
        required={true}
        register={register('description')}
        error={errors.description}
        placeholder="Describe your app"
        onClear={() => setValue('description', '')}
      />
      <Select
        label="Platforms"
        register={register('platforms')}
        error={errors.platforms}
      >
        <option value="discord">Discord</option>
        <option disabled value="telegram">
          Telegram
        </option>
        <option disabled value="reddit">
          Reddit
        </option>
      </Select>
      <TextArea
        showClear={Boolean(watch('feedback'))}
        label="How did you hear about us"
        register={register('feedback')}
        error={errors.feedback}
        placeholder="How did you hear about us"
        onClear={() => setValue('feedback', '')}
      />
      <Disclaimer>This form cannot be edited after submission</Disclaimer>
      <div className={style.actions}>
        <Button
          outline
          type="button"
          onClick={() => {
            Mixpanel.track('dev-portal mini-app-cancel-btn', {
              button: 'Cancel',
            });
            onCancel();
          }}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          data-testid="submit-button"
          onClick={() => {
            Mixpanel.track('dev-portal mini-app-submit-btn', {
              button: 'Submit',
            });
          }}
        >
          Submit
        </Button>
      </div>
    </form>
  );
}
