// import React from 'react';
// import MixPanelButton from 'components/mixPanelButton/mixPanelButton';
// import mascot from '../../images/mascot.png';
// export default function SupportedChains() {
//     return (
//         <div className="container my-12 mx-auto md:px-6">
//             <section className="mb-32 text-center lg:text-left">
//                 <div className="px-6 py-12 md:px-12">
//                     <div className="grid items-center lg:grid-cols-2 lg:gap-x-12">
//                         <div className="mb-12 lg:mb-0">
//                             <h2 className="my-12 text-5xl font-bold leading-tight tracking-tight">
//                                 We Support <br /><br />
//                                 <span className="text-purple-500 ">25 L1s and L2s</span>  <br />
//                                 <span className="text-pink-500 ">All popular token types</span>  <br />
//                                 <span className="text-success ">Custom Staking Contracts</span>
//                             </h2>
//                             <MixPanelButton />
//                         </div>
//                         <div className="mb-12 lg:mb-0 relative">
//                             <img
//                                 src={mascot}
//                                 className="w-full rounded-lg shadow-xl shadow-yellow-500/50 hover:shadow-yellow-500"
//                                 alt="Collab.Land Mascot"
//                             />
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </div>
//     );
// }
import React from 'react';

import MixPanelButton from 'components/mixPanelButton/mixPanelButton';

import mascot from '../../images/mascot.png';

import styles from './supportedChains.module.scss';

export default function SupportedChains() {
  return (
    <div className={styles.supportedchainscontainer}>
      <section className={styles.supportedchainssection}>
        <div className={styles.supportedchainscontent}>
          <div className={styles.supportedchainsgrid}>
            <div className={styles.supportedchainstext}>
              <h2 className={styles.supportedchainstitle}>
                <span className={styles.supportedchainsheading}>
                  Collab.Land supports:
                </span>{' '}
                <br />
                <br />
                <span className={`${styles.supportedchainshighlight} `}>
                  25 L1s and L2s
                </span>{' '}
                <span className={`${styles.supportedchainshighlight} `}>
                  All popular token types
                </span>{' '}
                <span className={`${styles.supportedchainshighlight} `}>
                  Custom Staking Contracts
                </span>
              </h2>
              <div className={styles.mixpanelbutton}>
                <MixPanelButton />
              </div>
            </div>
            <div className={styles.supportedchainsimage}>
              <img
                src={mascot}
                width={500}
                height={500}
                className={styles.supportedchainsimg}
                alt="Collab.Land Mascot"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
