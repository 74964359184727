import { HtmlHTMLAttributes, useEffect } from 'react';

import { ContributorMiniAppClaim } from 'types';

import { DeleteIcon } from 'icons/icons';

import {
  deleteMiniApp,
  getContributorMiniAppClaims,
  renameMiniApp,
  resetMiniAppKey,
} from 'reduxStore/contributor/contributor.slice';
import { useDispatch, useSelector } from 'reduxStore/hooks';
import { getCurrentOrganization } from 'reduxStore/organization/organization.slice';

import Button from 'components/button/button';
import EditableText from 'components/editableText/editableText';
import Confirm from 'components/modal/confirm';
import SecretKey from 'components/secretKey/secretKey';
import { SectionBody, SectionTitle } from 'components/section/section';

import qrCodePicture from 'images/qrCode.png';

import SubmissionLimitsGuardedButton from '../../submissionLimitsGuardedButton';
import CredentialCard from '../credentialCard';

import style from './contributor.module.scss';

type ContributorProps = HtmlHTMLAttributes<HTMLDivElement>;

function MiniAppsSubmissions(props: {
  claimRequests: ContributorMiniAppClaim[];
}) {
  const dispatch = useDispatch();

  return (
    <ul className={style.list} data-testid="miniapp-api-submissions">
      {props.claimRequests.map(req => (
        <li key={req.sk}>
          <SectionBody>
            <CredentialCard
              title={
                <EditableText
                  text={req.name}
                  onSave={async newName =>
                    dispatch(renameMiniApp({ sk: req.sk, newName })).unwrap()
                  }
                />
              }
              name={req.name}
              icon={req.icon}
              ID={req.sk}
              date={req.createdTime}
              status={req.status}
              actions={req.actions}
              buttons={
                <>
                  {req.publicKey && (
                    <Confirm
                      title="Reset Credentials"
                      description="This action cannot be undone."
                      onConfirm={() => dispatch(resetMiniAppKey(req.sk))}
                    >
                      <Button outline size="small">
                        Reset Credentials
                      </Button>
                    </Confirm>
                  )}
                  <Confirm
                    title="Delete"
                    color="danger"
                    description="This action cannot be undone."
                    onConfirm={() => dispatch(deleteMiniApp(req.sk))}
                  >
                    <Button outline size="small" color="danger">
                      <DeleteIcon className={style.buttonIcon} /> Delete
                    </Button>
                  </Confirm>
                </>
              }
            >
              {req.publicKey ? (
                <div className={style.publicKey}>
                  Public key <SecretKey value={req.publicKey} />
                </div>
              ) : null}
            </CredentialCard>
          </SectionBody>
        </li>
      ))}
    </ul>
  );
}

export default function Contributor({
  className,
  ...htmlProps
}: ContributorProps) {
  const { claimRequests, isContributorsBeingFetched } = useSelector(
    state => state.contributor,
  );
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganization).pk;
  const showIntroText = !isContributorsBeingFetched;

  useEffect(() => {
    dispatch(getContributorMiniAppClaims(organizationId));
  }, [dispatch, organizationId]);

  return (
    <div className={className} {...htmlProps}>
      <SectionTitle title="Marketplace" thumbnail={qrCodePicture}>
        <SubmissionLimitsGuardedButton
          appType="contributor"
          label="Submit Application"
          to="contributor/mini-app/add"
        />
      </SectionTitle>
      {!claimRequests.length ? (
        <SectionBody>
          {isContributorsBeingFetched ? 'Loading...' : null}
          {showIntroText ? (
            <div data-testid="contributor-intro-text">
              <h3>Build a miniapp for the marketplace</h3>
              <p>
                Build a miniapp for the Collab.Land marketplace and gain access
                to over 50k+ Discord communities.
              </p>
            </div>
          ) : null}
        </SectionBody>
      ) : null}
      <MiniAppsSubmissions claimRequests={claimRequests} />
    </div>
  );
}
