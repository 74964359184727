import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import cx from 'classnames';
import { Mixpanel } from 'mixpanel';

import { SuccessIcon, ErrorIcon } from 'icons/icons';

import { useSelector } from 'reduxStore/hooks';
import { getCurrentOrganization } from 'reduxStore/organization/organization.slice';

import Button from 'components/button/button';
import { Text } from 'components/text/text';

import { dashboardUrl } from '../../constants';

import style from './subscriptionResult.module.scss';

export default function SubscriptionResult() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { planDetail } = useSelector(getCurrentOrganization);
  const message = searchParams.get('message');
  const success = searchParams.get('success');
  const isSuccess = success === 'true';
  useEffect(() => {
    Mixpanel.track(`dev-portal subscription-result`, {
      isSubscriptionSuccess: success,
    });
  });
  useEffect(() => {
    const isPageLoadedByRedirection = success !== null;

    if (!isPageLoadedByRedirection) {
      navigate(dashboardUrl);
    }
  }, [navigate, success]);

  return (
    <div className={style.subscriptionResult}>
      <h2 className={style.title}>
        {isSuccess ? (
          <>
            <SuccessIcon className={cx([style.icon, style.successIcon])} />
            <Text tag="div">Subscription completed</Text>
          </>
        ) : (
          <>
            <ErrorIcon className={cx([style.icon, style.errorIcon])} />
            <Text tag="div">Subscription failed</Text>
          </>
        )}
      </h2>
      <div className={style.content}>
        {isSuccess ? (
          <div>You have subscribed to {planDetail.name}</div>
        ) : (
          message
        )}
      </div>
      <div className={style.goBackButtonWrapper}>
        <Button outline onClick={() => navigate(dashboardUrl)}>
          Return to Dashboard
        </Button>
      </div>
    </div>
  );
}
