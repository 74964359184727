import { HtmlHTMLAttributes, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ConsumerClientAppClaim } from 'types';

import { DeleteIcon, EditIcon } from 'icons/icons';

import {
  deleteClientApp,
  getConsumerClientAppClaims,
  resetClientAppKey,
} from 'reduxStore/consumer/consumer.slice';
import { useDispatch, useSelector } from 'reduxStore/hooks';
import { getCurrentOrganization } from 'reduxStore/organization/organization.slice';

import useClipboard from 'hooks/useClipboard';

import Button from 'components/button/button';
import CopyApiKey from 'components/consumer/copyApiKey';
import Confirm from 'components/modal/confirm';
import Modal from 'components/modal/modal';
import { SectionBody, SectionTitle } from 'components/section/section';

import keyPicture from 'images/key.png';

import SubmissionLimitsGuardedButton from '../../submissionLimitsGuardedButton';
import CredentialCard from '../credentialCard';

import style from './consumer.module.scss';

type ConsumerProps = HtmlHTMLAttributes<HTMLDivElement>;

function ApiClaimRequests(props: { claimRequests: ConsumerClientAppClaim[] }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { copy } = useClipboard({ name: 'Secret key' });
  const [clientApp, setClientApp] = useState<null | ConsumerClientAppClaim>(
    null,
  );

  const handleResetKeyConfirmation = async (sk: string) => {
    const data = await dispatch(resetClientAppKey(sk)).unwrap();

    setClientApp(data);
  };

  return (
    <>
      <ul className={style.list} data-testid="clientapp-api-submissions">
        {props.claimRequests.map(req => (
          <li key={req.sk}>
            <SectionBody>
              <CredentialCard
                title={
                  <>
                    {req.name}
                    <button
                      aria-label="edit"
                      className={style.editButton}
                      onClick={() => {
                        navigate(`/consumer/client-app/${req.id}/edit`);
                      }}
                    >
                      <EditIcon />
                    </button>
                  </>
                }
                name={req.name}
                icon={req.icon}
                ID={req.sk}
                date={req.createdTime}
                status={req.status}
                actions={req.actions}
                buttons={
                  <>
                    {req.status !== 'REVOKED' && (
                      <Confirm
                        title="Reset Credentials"
                        description="This action cannot be undone."
                        onConfirm={() => handleResetKeyConfirmation(req.sk)}
                      >
                        <Button outline size="small">
                          Reset Credentials
                        </Button>
                      </Confirm>
                    )}
                    <Confirm
                      title="Delete Client app"
                      color="danger"
                      description="This action cannot be undone."
                      onConfirm={() => dispatch(deleteClientApp(req.sk))}
                    >
                      <Button outline size="small" color="danger">
                        <DeleteIcon className={style.buttonIcon} /> Delete
                      </Button>
                    </Confirm>
                  </>
                }
              />
            </SectionBody>
          </li>
        ))}
      </ul>
      <Modal
        enforce
        open={Boolean(clientApp)}
        className={style.copyApiKeyModal}
      >
        {clientApp && (
          <CopyApiKey clientApp={clientApp} onCopy={data => copy(data)} />
        )}
        <div className={style.doneButtonWrapper}>
          <Button outline onClick={() => setClientApp(null)}>
            Done
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default function Consumer({ className, ...htmlProps }: ConsumerProps) {
  const { claimRequests, isConsumersBeingFetched } = useSelector(
    state => state.consumer,
  );
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganization).pk;
  const showIntroText = !isConsumersBeingFetched;

  useEffect(() => {
    dispatch(getConsumerClientAppClaims(organizationId));
  }, [dispatch, organizationId]);

  return (
    <div className={className} {...htmlProps}>
      <SectionTitle title="Client apps" thumbnail={keyPicture}>
        <SubmissionLimitsGuardedButton
          appType="consumer"
          label="Request API Access"
          to="consumer/client-app/add"
        />
      </SectionTitle>
      {!claimRequests.length ? (
        <SectionBody>
          {isConsumersBeingFetched ? 'Loading...' : null}
          {showIntroText ? (
            <div data-testid="consumer-intro-text">
              <h3>Request API Access</h3>
              <p>
                Request Client apps to access Collab.Land's API endpoints in
                your projects.
              </p>
            </div>
          ) : null}
        </SectionBody>
      ) : null}
      <ApiClaimRequests claimRequests={claimRequests} />
    </div>
  );
}
