import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import bannerImage from '../../images/banner.png';

type AppHeroProps = {
  ctaButton: React.ReactNode;
};

export default function AppHero({ ctaButton }: AppHeroProps) {
  const [showTitle2, setShowTitle2] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setShowTitle2(prev => !prev);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const title1 = 'Reach 50K+ communities via the miniapp Marketplace';
  const title2 = 'Create custom token gating experiences with Collab.Land APIs';

  const stats = [
    {
      title: 'Communities',
      value: '50K+',
      icon: 'fa-solid fa-people-group',
      color: '#ff0000', // Red
    },
    {
      title: 'Supported L1s & L2s',
      value: '25+',
      icon: 'fa-solid fa-wallet',
      color: '#0000ff', // Blue
    },
    {
      title: 'Reach',
      value: '100M+',
      icon: 'fas fa-users',
      color: '#ffff00', // Yellow
    },
    {
      title: 'New Wallets Added Daily',
      value: '4K+',
      icon: 'fa-solid fa-person-walking-arrow-right',
      color: '#00ff00', // Green
    },
  ];

  return (
    <header>
      <div className="flex flex-col justify-center p-4 lg:p-10 mx-auto">
        <div className="w-full max-w-screen-xl mx-auto px-4">
          <div className="sm:rounded-3xl bg-gradient-to-r from-yellow-700 to-yellow-500 py-6">
            <div className="px-4 lg:px-20 py-6 flex flex-col lg:flex-row items-center justify-between">
              <div className="lg:w-2/4 mt-4 lg:mt-20 lg:ml-16 text-left">
                <div className="text-3xl lg:text-6xl font-semibold text-gray-900 leading-none">
                  <span className={` ${showTitle2 ? 'hidden' : ''}`}>
                    {title1}
                  </span>
                  <span className={` ${showTitle2 ? '' : 'hidden'}`}>
                    {title2}
                  </span>
                </div>
                <div className="mt-4 lg:mt-6 text-lg lg:text-xl font-light text-white">
                  Get immediate distribution of your projects to 50K+
                  communities on Collab.Land with miniapps. Build best-in-class
                  token gating experiences with our APIs.
                </div>
                {ctaButton}
              </div>
              <div className="lg:w-2/4 lg:block hidden">
                <img
                  className="ml-16"
                  height={350}
                  width={400}
                  src={bannerImage}
                  alt="hero"
                />
              </div>
            </div>
          </div>
          <section className="bg-transparent m-3 rounded">
            <div className="flex flex-col lg:flex-row items-center gap-3">
              {stats.map((stat, index) => (
                <div className="flex justify-evenly items-center w-96 lg:w-1/3">
                  <div
                    key={index}
                    className="flex justify-evenly items-center w-96 lg:w-1/3"
                  >
                    <div className="text-center uppercase">
                      <h2 className="text-4xl font-bold">{stat.value}</h2>
                      <h4 className="inline text-gray-300 text-md">
                        {stat.title}
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </header>
  );
}

AppHero.propTypes = {
  ctaButton: PropTypes.element.isRequired,
};
