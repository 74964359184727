import { HtmlHTMLAttributes, PropsWithChildren } from 'react';

import cx from 'classnames';

import style from './section.module.scss';

type SectionTitleProps = PropsWithChildren & {
  title: string;
  thumbnail: string;
};

export function SectionTitle({
  title,
  thumbnail,
  children,
}: SectionTitleProps) {
  return (
    <div className={style.sectionTitle}>
      <div className={style.title}>
        <img
          src={thumbnail}
          alt=""
          role="presentation"
          width="18"
          data-testid="thumbnail"
        />
        <span>{title}</span>
      </div>
      <div>{children}</div>
    </div>
  );
}

export type SectionBodyProps = PropsWithChildren &
  HtmlHTMLAttributes<HTMLDivElement>;

export function SectionBody({
  className,
  children,
  ...htmlProps
}: SectionBodyProps) {
  const classNames = cx(style.wrapper, className);

  return (
    <div className={classNames} {...htmlProps}>
      {children}
    </div>
  );
}
