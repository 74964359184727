import cx from 'classnames';

import Arbitrum from 'images/chains/arbitrum.png';
import Avalanche from 'images/chains/avalanche.png';
import Eluvio from 'images/chains/eluvio.png';
import Eth from 'images/chains/eth.png';
import Flow from 'images/chains/flow.png';
import Immutable from 'images/chains/immutable.png';
import Kusama from 'images/chains/kusama.png';
import Loopring from 'images/chains/loopring.png';
import Moonbeam from 'images/chains/moonbeam.png';

import s from './chainCarousel.module.scss';

const ChainCarouselLeft = () => {
  return (
    <div id="infinite" className={s.highwaySlider}>
      <div className={cx(s.container, s.highwayBarrier)}>
        <ul className={s.highwayLane}>
          <li className={s.highwayCar}>
            <img src={Avalanche} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Arbitrum} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Eluvio} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Eth} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Flow} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Immutable} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Kusama} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Loopring} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Moonbeam} height="auto" width="auto" alt="" />
          </li>

          <li className={s.highwayCar}>
            <img src={Avalanche} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Arbitrum} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Eluvio} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Eth} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Flow} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Immutable} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Kusama} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Loopring} height="auto" width="auto" alt="" />
          </li>
          <li className={s.highwayCar}>
            <img src={Moonbeam} height="auto" width="auto" alt="" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ChainCarouselLeft;
