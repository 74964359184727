
import { Link } from 'react-router-dom';
import React from 'react';
import style from './appFooter.module.scss';
import CollabLogo from 'images/collab-logo.png';

export default function AppFooter() {
  return (
    <div className={style.footer}>
      <div className={style.logo} data-testid="logo">
        <Link to="/">
          <img src={CollabLogo} alt="Collab.Land Dev Portal" />
        </Link>
      </div>
      <div className={style.footerLinks}>
        <a className={style.linkItem} href="https://twitter.com/Collab_Land_" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a className={style.linkItem} href="https://discord.com/invite/collabland" target="_blank" rel="noopener noreferrer">Discord</a>
        <a className={style.linkItem} href="https://medium.com/collab-land" target="_blank" rel="noopener noreferrer">Medium</a>
        <a className={style.linkItem} href="https://collabland.substack.com" target="_blank" rel="noopener noreferrer">Substack</a>
        <a className={style.linkItem} href="https://www.youtube.com/@CollabLand" target="_blank" rel="noopener noreferrer">Youtube</a>
      </div>
    </div>
  );
}
